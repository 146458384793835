import React from 'react';
import {useWindowSizingProvider} from './windowSizingProvider';
import {useAnalyticsDataProvider} from './analyticsDataProvider';
import {useConversationsProvider} from './conversationsProvider';
import {useReactionsProvider} from './reactionsProvider';
import {useSelectedConversationProvider} from './selectedConversationProvider';
import {useSelectedReactionGroupProvider} from './selectedReactionProvider';
import {useCanvasProvider} from './canvasProvider';
import {useCommentsProvider} from './commentsProvider';
import {useAnnotationManagerProvider} from './annotationManagerProvider';
import {useDisableConversationProvider} from './disableConversationProvider';
import {useLibraryProvider} from './libraryProvider';
import {useThemeProvider} from './themeProvider';
import {useCollectionsProvider} from './collectionsProvider';
import {useTeamsProvider} from './teamsProvider';
import {useCollectionMediaProvider} from './collectionMediaProvider';
import {useFoldersProvider} from './foldersProvider';
import {useBatchSelectionProvider} from './batchSelectionProvider';
import {useQuizDataProvider} from './quizDataProvider';
import {useMediaProvider} from './mediaProvider';
import {useCaptionDataProvider} from './captionDataProvider';
import {useSortMetricProvider} from '../service/sortMetricProvider';
import {useUserQuotaProvider} from './userQuotaProvider';
import {useMigrationProvider} from './migrationProvider';
import {useSelfIdentifiedUserProvider} from './selfIdentifiedUserProvider';
import {useMediaOperationsProvider} from './mediaOperationsProvider';
import {useTranscriptDataProvider} from './transcriptProvider';
import {useMediaTabsProvider} from './mediaTabsProvider';

interface IStateProviderProps {
   isOwnerOrAdmin?: boolean;
   children?: React.ReactElement;
   isHeader?: boolean;
   isWatchPage?: boolean;
   isLibrary?: boolean;
   isTeams?: boolean;
   isCollections?: boolean;
   isEmbed?: boolean;
   isMigrationMediaDownload?: boolean;
}

const StateProvider: React.FC<IStateProviderProps> = ({children, isOwnerOrAdmin = false, isHeader = false, isWatchPage = false, isLibrary = false, isTeams = false, isCollections = false, isEmbed = false, isMigrationMediaDownload = false}) => {
   let providers;

   if (isHeader) {
      providers = [
         useLibraryProvider,
         useCollectionsProvider,
         useFoldersProvider,
         useThemeProvider,
         useUserQuotaProvider,
         useTeamsProvider
      ];
   } else if (isLibrary) {
      providers = [
         useLibraryProvider,
         useThemeProvider,
         useCollectionsProvider,
         useTeamsProvider,
         useCollectionMediaProvider,
         useFoldersProvider,
         useBatchSelectionProvider,
         useMediaProvider,
         useSortMetricProvider,
         useUserQuotaProvider,
         useMigrationProvider
      ];
   } else if (isTeams) {
      providers = [
         useLibraryProvider,
         useCollectionsProvider,
         useTeamsProvider,
         useFoldersProvider,
         useThemeProvider,
         useUserQuotaProvider,
         useMediaProvider,
         useBatchSelectionProvider
      ];
   } else if (isCollections) {
      providers = [
         useLibraryProvider,
         useThemeProvider,
         useCollectionsProvider,
         useTeamsProvider,
         useCollectionMediaProvider,
         useFoldersProvider,
         useBatchSelectionProvider,
         useMediaProvider,
         useSortMetricProvider,
         useUserQuotaProvider,
         useMigrationProvider
      ];
   } else if (isWatchPage) {
      providers = [
         useWindowSizingProvider,
         useSelectedConversationProvider,
         useSelectedReactionGroupProvider,
         useCanvasProvider,
         useCommentsProvider,
         useAnnotationManagerProvider,
         useConversationsProvider,
         useReactionsProvider,
         useDisableConversationProvider,
         useThemeProvider,
         useCollectionsProvider,
         useTeamsProvider,
         useQuizDataProvider,
         useMediaProvider,
         useCaptionDataProvider,
         useFoldersProvider,
         useUserQuotaProvider,
         useSelfIdentifiedUserProvider,
         useMigrationProvider,
         useMediaOperationsProvider,
         useTranscriptDataProvider,
         useMediaTabsProvider
      ];

      isOwnerOrAdmin && providers.push(
         useAnalyticsDataProvider
      );
   } else if (isEmbed) {
      providers = [
         useMediaProvider,
         useUserQuotaProvider,
         useConversationsProvider,
         useThemeProvider,
         useReactionsProvider,
         useSelectedReactionGroupProvider,
         useSelfIdentifiedUserProvider
      ];
   } else if (isMigrationMediaDownload) {
      providers = [
         useMigrationProvider
      ];
   } else {
      providers = [
         useLibraryProvider,
         useCollectionsProvider,
         useTeamsProvider,
         useFoldersProvider,
         useThemeProvider,
         useUserQuotaProvider
      ];
   }

   return providers.reduce((tree, provider) => provider(tree), children);
};

export default StateProvider;

import React, {createContext, ReactElement, useState} from 'react';
import {ITranscript} from '../model/captionModels';

interface ITranscriptDataProvider {
   transcript: ITranscript;
}

const initialState: ITranscriptDataProvider = {transcript: null};

const store = createContext(initialState);
const Provider = store.Provider;

let privateState = initialState;

let privateUpdateState: React.Dispatch<React.SetStateAction<ITranscriptDataProvider>> = () => null;

const publicUpdateState = (newState: Partial<ITranscriptDataProvider>) => {
   privateUpdateState(Object.assign({}, privateState, newState));
};

const useProvider = (children: ReactElement) => {
   const [state, updateState] = useState<ITranscriptDataProvider>(initialState);

   privateUpdateState = updateState;
   privateState = state;

   return (
      <Provider value={state}>
         {children}
      </Provider>
   );
};

export {
   useProvider as useTranscriptDataProvider,
   store as transcriptStore,
   publicUpdateState as updateTranscriptDataState
};

/* eslint-disable no-magic-numbers */
const adminBasePath = '/Admin';

export enum MigrationName {
   none = 'none',
   screencast = 'legacy-screencast'
}

export enum MigrationState {
   none = 'None',
   inprogress = 'InProgress',
   completed = 'Completed',
   failed = 'Failed'
}

export enum ButtonActivationKeys {
   enter = 'Enter',
   space = 'Space'
}

export enum AnnotationModes {
   edit = 'Edit',
   display = 'Display',
   none = 'None'
}

export enum AnnotationTools {
   freehand = 'Freehand',
   arrow = 'Arrow',
   square = 'Square',
   none = 'None'
}

export enum AnnotationColors {
   white = '#fff',
   blue = '#00aeff',
   green = '#36be5f',
   red = '#ff6666',
   yellow = '#ffc92f'
}

export enum UserTypes {
   techSmith = 'TechSmith',
   selfIdentified = 'SelfIdentified'
}

export enum PrivacyTypes {
   link = 'link',
   invite = 'invite'
}

export enum CollectionPrivacyTypes {
   public = 'Public',
   invite = 'Private'
}

export enum LibraryTypes {
   personal = 'Personal',
   team = 'Team'
}

export enum NotificationEventTypes {
   firstView = 'FirstView',
   newComment = 'NewComment',
   newCommentReply = 'NewCommentReply',
   collectionMediaAdded = 'CollectionMediaAdded',
   collectionMediaAddedBatch = 'CollectionMediaAddedBatch',
   collectionMediaAddedSingle = 'CollectionMediaAddedSingle'
}

export enum GroupRoleTypes {
   notMember = 'NotMember',
   participant = 'Participant',
   contributor = 'Contributor',
   manager = 'Manager'
}

export enum CreationProducts {
   UNKNOWN = 'UNKNOWN',
   SNAGIT = 'SNAGIT',
   CAMTASIA = 'CAMTASIA',
   AUDIATE = 'AUDIATE',
   CAPTURE = 'CAPTURE',
   FUSE = 'FUSE',
   CLASSICRECORDER = 'CLASSICRECORDER',
   WEBUPLOAD = 'WEBUPLOAD',
   INGESTION = 'INGESTION',
   MATROX = 'MATROX',
   ZOOM = 'ZOOM',
   COACHSEYE = 'COACHSEYE',
   BROWSER = 'BROWSER',
   SERVICE = 'SERVICE',
   COPY = 'COPY',
   YOUTUBE = 'YOUTUBE',
   TEST = 'TEST'
}

export const collectionRoleTypeAsInteger = (role: GroupRoleTypes): number => {
   switch (role) {
      case GroupRoleTypes.notMember: return 0;
      case GroupRoleTypes.participant: return 1;
      case GroupRoleTypes.contributor: return 2;
      case GroupRoleTypes.manager: return 3;
      default: return 0;
   }
};

export enum KeyCodes {
   enter = 13,
   space = 32,
   backspace = 8,
   escape = 27,
   delete = 46,
   shift = 16
}

export enum Keys {
   escape = 'Escape',
   shift = 'Shift',
   ctrl = 'Control',
   meta = 'Meta'
}

export enum DeliveryTypes {
   Email = 'Email',
   Web = 'Web'
}

export enum BehaviorFilters {
   all = 'All',
   partial = 'Partial',
   completed = 'Completed'
}

export enum UserPermissions {
   siteAdmin = 'SiteAdministration',
   captionAdmin = 'CaptionAdministration',
   canCreate = 'ContentCreation'
}

export enum NavigationTabTypes {
   myLibrary = 'MyLibrary',
   myFolders = 'MyFolders',
   myCollections = 'MyCollections',
   allCollections = 'AllCollections',
   myFeed = 'MyFeed',
   accessibility = 'Accessibility',
   admin = 'Admin',
   followedCollections = 'FollowedCollections',
   teams = 'Teams'
}

export enum SortMetric {
   titleAscending = 'title_ascending',
   titleDescending = 'title_descending',
   dateAscending = 'date_ascending',
   dateDescending = 'date_descending',
   viewsAscending = 'views_ascending',
   viewsDescending = 'views_descending'
}

export enum OrderByQueryString {
   date = 'date',
   title = 'title',
   views = 'views'
}

export enum EntryPoint {
   header = 'Header',
   feed = 'Feed',
   library = 'Library',
   teams = 'Teams',
   register = 'Register',
   watch = 'Watch',
   embed = 'Embed',
   launcher = 'Launcher',
   migrationMediaDownload = 'Migration Media Download',
   settings = 'Settings',
   whatsNew = 'WhatsNew',
   noAccess = 'NoAccess'
}

export enum RegistrationStep {
   activate = 'Activate',
   acceptTos = 'AcceptTos',
   startTrialOrPurchase = 'StartTrialOrPurchase'
}

export enum StatusPages {
   accessDenied = 'AccessDenied',
   accessPhraseRequired = 'AccessPhraseRequired',
   classicRecorderLoginSuccess = 'ClassicRecorderLoginSuccess',
   classicRecorderLogoutSuccess = 'ClassicRecorderLogoutSuccess',
   classicRecordersUnavailable = 'ClassicRecordersUnavailable',
   identityProviderMismatch = 'IdentityProviderMismatch',
   internalServerError = 'InternalServerError',
   ltiBlockedCookiesAddContent = 'LtiBlockedCookiesAddContent',
   ltiNotAuthorized = 'LtiNotAuthorized',
   ltiPrivate = 'LtiPrivate',
   ltiSignInFailed = 'LtiSignInFailed',
   ltiSwitchedUser = 'LtiSwitchedUser',
   mediaUnavailable = 'MediaUnavailable',
   noContentSelected = 'NoContentSelected',
   notFound = 'NotFound',
   notSignedIn = 'NotSignedIn',
   readOnlyMode = 'ReadOnlyMode',
   searchUnavailable = 'SearchUnavailable',
   thirdPartyTranscriptionPendingApproval = 'ThirdPartyTranscriptionPendingApproval',
   transcriptionInProgress = 'TranscriptionInProgress',
   wrongEmailDomain = 'WrongEmailDomain'
}

export enum EntitlementNames {
   knowmiaPro = 'KnowmiaPro',
   screencastPro = 'ScreencastPro',
   screencastProUnlimited = 'ScreencastProUnlimited'
}

export enum ServiceNames {
   knowmia = 'Knowmia',
   screencast = 'Screencast',
}

export enum EmbedPlatforms {
   slack = 'slack'
}

export enum LinkIDs {
   helpSupport = 'helpSupport',
   helpTutorials = 'helpTutorials',
   helpFileFormats = 'helpFileFormats'
}

export enum MigrationExportStatus {
   incomplete = 'Incomplete',
   success = 'Success',
   failed = 'Failed'
}

export default {
   swrKey: 'swr',
   mainElementClassName: 'main-container',
   mediaPaginationBatchSize: 25,
   collectionsPaginationBatchSize: 25,
   waitTime: 5000,
   maxAccessPhraseLength: 40,
   maxFolderNameLength: 100,
   maxCollectionNameLength: 100,
   maxSelfIdentifyNameLength: 64,
   maxCommentLength: 4000,
   defaultMediaHeight: 1080,
   defaultMediaWidth: 1920,
   myLibrary: 'myLibrary',
   progressBarKey: 'encodingProgress',
   localDevServerUrl: 'http://localhost:3001',
   themeKey: 'themeName',
   cursors: {
      default: 'default'
   },
   pluginType: {
      annotation: 'Annotation',
      markers: 'Markers'
   },
   pluginElements: {
      progressBar: 'progressBar',
      media: 'media',
      beforeAfterPlayControls: 'beforeAfterPlayControls',
      player: 'player'
   },
   annotation: {
      timeTolerance: 0.1,
      animationDuration: 200,
      scale: 1000,
      doubleClickDelay: 300,
      shape: {
         rect: 'rect',
         freehand: 'path',
         arrow: 'arrow'
      },
      controlsPadding: 10,
      strokeWidth: 5,
      colors: ['#FFF', '#00AEFF', '#36BE5F', '#FF6666', '#FFC92F'],
      defaultColor: AnnotationColors.red,
      transparentColor: 'transparent',
      newScaleAnnotationFlag: 'version'
   },
   conversationRefreshInterval: 60000,
   getDomElementTimeout: 100,
   colorClass: {
      white: 'white',
      blue: 'blue',
      green: 'green',
      red: 'red',
      yellow: 'yellow'
   },
   reactionTypes: {
      like: 'Like'
   },
   navigation: {
      local: {
         root: '/',
         myLibraryLink: '/library.html',
         myFeedLink: '/feed.html',
         teamsLink: '/teams.html'
      },
      foldersRoot: '/folder',
      foldersLink: '/folder/{folderHash}',
      usersRootLink: '/users',
      myLibraryLink: '/myprofile',
      myLibraryRecentMediaLink: '/myprofile/recent',
      myLibraryAllVideosLink: '/myprofile/videos',
      myLibraryAllImagesLink: '/myprofile/images',
      myFeedLink: '/myprofile/feed',
      myCollectionsLink: '/myprofile/collections',
      teamsLink: '/teams',
      manageCollectionBasePath: '/myprofile/collections',
      collectionsViewBasePath: '/collections',
      accessibilityLink: '/accessibility',
      captioningActivityLink: '/accessibility#/captioning',
      captionEditor: '/caption/{mediaHash}',
      knowmiaFeedbackLink: 'https://techsmith.az1.qualtrics.com/jfe/form/SV_0fAxNg2cVlwWNbo?Source=TechSupport',
      termsOfUseLink: '/TechSmithKnowmiaGeneralTermsofUse.pdf',
      termsOfServiceLink: '/TechSmithKnowmiaTermsofService.pdf',
      termsOfServiceScreencastLink: '/TechSmithScreencastTermsofService.pdf',
      adminTermsOfServiceLink: '/TermsOfService',
      securityPolicyLink: '/TechSmithRelaySecurityPolicy.pdf',
      contactSupportLink: 'https://support.techsmith.com/hc/en-us',
      contactAdminLink: '/support',
      acknowledgementsLink: '/Acknowledgements',
      userSettingsLink: '/settings',
      whatsNew: '/whats-new',
      camtasiaWeb: '/camtasia',
      noAccessLink: '/no-access',
      changeMediaLink: '/connector/LtiSelection/ChangeVideo?rlid={resourceLinkId}',
      knowmiaFAQLink: 'https://support.techsmith.com/hc/en-us/articles/360044662811#4',
      screencastFAQLink: 'https://www.techsmith.com/redirect.asp?target=HELP_FILEFORMATS&ver=2.*.*&lang=enu&os=na&product=screencast',
      customLogoMediaFAQLink: 'https://www.techsmith.com/redirect.asp?target=HELP_LOGO_FILEFORMATS&ver=2.*.*&lang=enu&os=na&product=screencast',
      browserDoesNotAllowCookiesSupportLink: 'https://support.techsmith.com/hc/en-us/articles/360042442092',
      knowmiaImportFromMobileTutorialLink: 'https://www.techsmith.com/tutorial-knowmia-import-from-mobile-device.html',
      systemRequirementLink: 'https://www.techsmith.com/techsmith-knowmia-system-requirements.html',
      downloadClassicRecorderWin: '/download/DownloadRecorder/win',
      downloadClassicRecorderMac: '/download/DownloadRecorder/mac',
      classicRecorderImageUrl: 'https://present.cloud.tsc-dev.co/Content/img/download-relay-recorder.png',
      connectMobileQrCodePath: '/download/GenerateQrCode',
      legacyConnectMobileQrCodePath: '/download/GenerateLegacyQrCode',
      launchCaptureLink: '/connector/record',
      quizRetirementLink: 'https://support.techsmith.com/hc/en-us/articles/14539861012237',
      migration: {
         learnMore: 'https://www.techsmith.com/redirect.asp?target=HELP_MIGRATIONGUIDE&ver=2.*.*&lang=enu&os=na&product=screencast',
         contactSupport: 'https://www.techsmith.com/redirect.asp?target=HELP_CONTACT_SUPPORT&ver=2.*.*&lang=enu&os=na&product=screencast',
         learnWhatsNew: 'https://www.techsmith.com/redirect.asp?target=HELP_MIGRATIONGUIDE&ver=2.*.*&lang=enu&os=na&product=screencast'
      },
      admin: {
         adminLink: adminBasePath,
         analyticsLink: `${adminBasePath}#/reports?pagesize=20`,
         usersAndRolesLink: `${adminBasePath}#/users`,
         mediaLink: `${adminBasePath}#/media`,
         settingsLink: `${adminBasePath}#/settings`,
         contanctTechsmithLink: `${adminBasePath}#/contact`
      },
      knowmia: {
         helpTutorials: 'https://www.techsmith.com/tutorial-knowmia.html?utm_source=product&utm_medium=knowmia&utm_campaign=knowmia&utm_content=tutorialbtn',
         helpFileFormats: 'https://support.techsmith.com/hc/en-us/articles/360042879151'
      },
      screencast: {
         callForUnlimitedLink: 'https://www.techsmith.com/redirect.asp?target=callForUnlimited&ver=2.*.*&lang=enu&os=na&product=screencast',
         helpTutorials: 'https://www.techsmith.com/redirect.asp?target=HELP_TUTORIALS&ver=2.*.*&lang=enu&os=na&product=screencast',
         helpSupport: 'https://www.techsmith.com/redirect.asp?target=HELP_SUPPORT&ver=2.*.*&lang=enu&os=na&product=screencast',
         helpFileFormats: 'https://www.techsmith.com/redirect.asp?target=HELP_FILEFORMATS&ver=2.*.*&lang=enu&os=na&product=screencast',
         missingMediaLearnMore: 'https://www.techsmith.com/redirect.asp?target=missingMediaLearnMore&ver=2.*.*&lang=enu&os=na&product=screencast'
      },
      banners: {
         workPlaceComms: 'https://www.techsmith.com/snagit-workplace-communication.html',
         screenRecorder: 'https://www.techsmith.com/screen-recorder.html',
         screenCapture: 'https://www.techsmith.com/screen-capture.html',
         camtasiaProduct: 'https://www.techsmith.com/video-editor.html',
         camtasiaRev: 'https://www.techsmith.com/camtasia-rev.html',
         camtasiaBestVideoEditing: 'https://www.techsmith.com/best-video-editing-software-beginners.html',
         utmProductIdentifier: {
            snagit: 'snagit',
            camtasia: 'camtasia',
            capture: 'capture',
            web: 'webupload'
         }
      }
   },
   localStorageKeys: {
      selfIdentifiedId: 'SelfIdentifiedId',
      selfIdentifiedSecret: 'SelfIdentifiedSecret',
      selfIdentifiedName: 'SelfIdentifiedName',
      editedComment: 'EditedComment',
      unsavedCommentText: 'UnsavedCommentText-{videoReviewId}-{inputId}',
      conversationCoachMark: 'ConversationCoachMarkClosed/{userId}',
      useMediaListView: 'UseMediaListView'
   },
   queryParams: {
      tab: 'tab',
      commentId: 'commentId',
      commentid: 'commentid',
      ltiSessionType: 'ltiSessionType',
      ltiSessionFolderHash: 'ltiSessionFolderHash',
      conversation: 'conversation',
      smartPlayerStartTime: 't',
      smartPlayerStartTimeUppercase: 'T',
      platform: 'platform',
      unsubscribe: 'unsubscribe',
      view: 'view'
   },
   statusCodes: {
      ok: 200,
      created: 201,
      accepted: 202,
      noContent: 204,
      partial: 206,
      unauthorized: 401,
      forbidden: 403,
      notFound: 404,
      conflict: 409,
      contentTooLarge: 413,
      unsupportedMediaType: 415,
      unprocessable: 422,
      serverError: 500
   },
   defaultMediaSize: {
      width: 1024,
      height: 576
   },
   elementInputTypes: {
      textArea: 'textarea',
      input: 'input',
      search: 'search'
   },
   rotation: {
      ninety: 90,
      oneEighty: 180,
      twoSeventy: 270
   },
   embedCode: {
      minWidthWarningInPixel: 320
   },
   analytics: {
      SnagitBannerDisplayed: 'promotions_snagit_show',
      CaptureBannerDisplayed: 'promotions_capture_show',
      CamtasiaBannerDisplayed: 'promotions_camtasia_show',
      WebUploadBannerDisplayed: 'promotions_web_show',
      SnagitEmbeddedBannerDisplayed: 'promotions_snagit_embed_show',
      CaptureEmbeddedBannerDisplayed: 'promotions_capture_embed_show',
      CamtasiaEmbeddedBannerDisplayed: 'promotions_camtasia_embed_show',
      WebUploadEmbeddedBannerDisplayed: 'promotions_web_embed_show',
      SnagitEmbeddedButtonClicked: 'promotions_snagit_embed_click',
      CaptureEmbeddedButtonClicked: 'promotions_snagit_embed_click',
      CamtasiaEmbeddedButtonClicked: 'promotions_camtasia_embed_click',
      WebUploadEmbeddedButtonClicked: 'promotions_web_embed_click',
      SnagitBannerButtonClicked: 'promotions_snagit_click',
      CaptureBannerButtonClicked: 'promotions_capture_click',
      CamtasiaBannerButtonClicked: 'promotions_camtasia_click',
      WebUploadBannerButtonClicked: 'promotions_web_click',
      SmartPlayerEventsPrefix: 'smartplayer_'
   },
   reactionMarkers: {
      emojiWidth: 14,
      maxNumberEmojiToPreview: 3,
      emojiOffset: 6,
      defaultTextForAnonymousReaction: 'Anonymous'
   },
   reactionsSummary: {
      width: 220,
      topMargin: 10,
      maxHeight: 200,
      edgeOffset: 2
   },
   defaultUploadFolderHash: '',
   emailValidationRegexp: '^([a-zA-Z0-9_\\-\\.\\+]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z0-9]{2,24})$',
   updatedCollectionsWindowEvent: 'pwc-collections-updated',
   updatedFoldersWindowEvent: 'pwc-folders-updated',
   shouldUpdateCollectionsWindowEvent: 'pwc-update-collections',
   shouldUpdateFoldersWindowEvent: 'pwc-update-folders',
   fetchedCollectionsWindowEvent: 'pwc-collections-fetched',
   fetchedRootFolderWindowEvent: 'pwc-root-folder-fetched',
   nullTechSmithId: '00000000-0000-0000-0000-000000000000',
   timeCodeRegex: /^((0?[0-9]:)?[0-5]?[0-9]):([0-5][0-9]) /,
   timeCodeWithNoSpaceRegex: /^((0?[0-9]:)?[0-5]?[0-9]):([0-5][0-9])/,
   chapters: {
      minimumGeneratedChapters: 2,
      maximumGeneratedChapters: 20,
      maximumChapters: 50,
      initialChaptersCutoffInSeconds: 3
   }
};

import {ScrollMode} from 'compute-scroll-into-view';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';

let latestCommentScrollTimeoutId = -1;
let latestElementScrollTimoutId = -1;

const smoothScrollWithDefaults = (node: HTMLElement, boundryElement: Element, options?: {delay?: number; alignment?: ScrollLogicalPosition; scrollMode?: ScrollMode}): number => {
   options = options || {};
   options.delay = options.delay || 100;
   options.alignment = options.alignment || 'start';
   options.scrollMode = options.scrollMode || 'if-needed';
   clearTimeout(latestElementScrollTimoutId);

   const smoothScrollOptions = {
      duration: 500,
      block: options.alignment,
      scrollMode: options.scrollMode,
      boundary: boundryElement
   };

   return window.setTimeout(() => scrollIntoView(node, smoothScrollOptions), options.delay);
};

export const scrollCommentIntoView = (commentNode: HTMLElement, options?: {delay?: number; alignment?: ScrollLogicalPosition; scrollMode?: ScrollMode}) => {
   clearTimeout(latestCommentScrollTimeoutId);
   latestCommentScrollTimeoutId = smoothScrollWithDefaults(commentNode, document.getElementById('side-panel-container'), options);
};

export const scrollElementIntoView = (commentNode: HTMLElement, boundryElmentId: string, options?: {delay?: number; alignment?: ScrollLogicalPosition; scrollMode?: ScrollMode}) => {
   clearTimeout(latestElementScrollTimoutId);
   latestElementScrollTimoutId = smoothScrollWithDefaults(commentNode, document.getElementById(boundryElmentId), options);
};

import React from 'react';
import {CollectionPrivacyOptionModel} from '../model/collectionPrivacyOptionModel';
import config from '../service/config';
import i18n from 'i18next';
import {Glyphs} from '@techsmith/tsc-cloud-style-guide';
import {CollectionPrivacyTypes} from '../constants/Constants';

export const getCollectionPrivacyOptions = () => {
   const isPublicOptionEnabled = config.arePublicVideosEnabled || config.isAdmin;

   const privacyExtras = {
      organizationName: config.organizationName,
      applicationShortName: config.applicationShortName
   };

   const publicTitle = i18n.t('privacy.collection.public.singleuser.title');
   const publicDescription = i18n.t('privacy.collection.public.singleuser.description', privacyExtras);
   const privateTitle = i18n.t('privacy.collection.invite.singleuser.title');
   const privateDescription = i18n.t('privacy.collection.invite.singleuser.description');

   const optionsMap = new Map<CollectionPrivacyTypes, CollectionPrivacyOptionModel>();

   optionsMap.set(CollectionPrivacyTypes.public, {
      value: CollectionPrivacyTypes.public,
      label: publicTitle,
      subtext: publicDescription,
      glyph: <Glyphs.PrivacyLink16x16/>,
      disabled: !isPublicOptionEnabled,
      badgeText: !isPublicOptionEnabled ? i18n.t('privacy.adminOnlyText') : ''
   });

   optionsMap.set(CollectionPrivacyTypes.invite, {
      value: CollectionPrivacyTypes.invite,
      label: privateTitle,
      subtext: privateDescription,
      glyph: <Glyphs.Lock16x16 />,
      disabled: false
   });

   return optionsMap;
};

import React, {createContext, ReactElement, useState} from 'react';

interface IMediaTabsProvider {
   currentTab: string;
   hasToggledMediaTabs: boolean;
   mediaTabsPanelOpen: boolean;
}

const initialState: IMediaTabsProvider = {
   currentTab: '',
   hasToggledMediaTabs: false,
   mediaTabsPanelOpen: true
};

const store = createContext<IMediaTabsProvider>(initialState);
const Provider = store.Provider;

let privateState = initialState;

let privateUpdateState: React.Dispatch<React.SetStateAction<IMediaTabsProvider>> = () => null;
const publicUpdateState = (newState: Partial<IMediaTabsProvider>) => privateUpdateState(Object.assign({}, privateState, newState));

const useProvider = (children: ReactElement) => {
   const [state, updateState] = useState<IMediaTabsProvider>(initialState);

   privateUpdateState = updateState;
   privateState = state;

   return (
      <Provider value={state}>
         {children}
      </Provider>
   );
};

export {
   useProvider as useMediaTabsProvider,
   store as mediaTabsStore,
   publicUpdateState as updateMediaTabsState
};

/* eslint-disable @typescript-eslint/no-unsafe-return */
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {ControlButton, TscButtonType, Label, Spinner, TscThemeName, TscThemeNames, TscStyles, TextInput} from '@techsmith/tsc-cloud-style-guide';
import {IMemberDetailsWithProfileImageModel, IUserProfileWithEmailModel} from '../../model/appModel';
import Constants, {GroupRoleTypes} from '../../constants/Constants';
import {themeStore} from '../../context/themeProvider';
import withMemoizedContexts from '../../context/contextContainerHoC';
import {useTranslation} from 'react-i18next';
import cssConstants from '../../constants/cssConstants';
import CollectionMemberListItem from '../modals/CollectionMembersModal/CollectionMemberListItem';
import {StyledWarning} from '../util/StyledElements';
import {scrollElementIntoView} from '../../util/CommentScroller';
import profileApi from '../../service/profileApi';
import UserNotFoundError from '../../class/userNotFoundError';
import logging from '../../service/logging';

const MembersLabelContainer = styled.div<{ theme: TscThemeNames }>`
   margin-bottom: 0.5rem;
`;

const MembersCount = styled.span<{theme: TscThemeNames}>`
   background-color: ${props => props.theme === TscThemeName.dusk ? TscStyles.color.ui.dusk.dark : TscStyles.color.ui.dawn.medium};
   margin-left: 0.75rem;
   padding: 0.25rem;
   border-radius: ${TscStyles.border.radius.sm};
   font-size: 0.875rem;
   font-weight: ${TscStyles.font.weight.semibold};
`;

const EmailInputContainer = styled.div`
   display: flex;
   margin-bottom: 1rem;

   > button {
      margin-left: 0.5rem;
   }
`;

const FlexContainer = styled.div`
   display: flex;
   flex-direction: column;
   width: 100%;
`;

const EmailListContainer = styled.div<{theme: TscThemeNames}>`
   display: flex;
   flex-direction: column;
   height: 10rem;
   border: 1px solid ${props => props.theme === TscThemeName.dusk ? TscStyles.color.ui.dusk.dark : TscStyles.color.ui.dawn.medium};
   border-radius: ${TscStyles.border.radius.md};
   overflow-x: hidden;
   overflow-y: auto;
`;

const LoadingContainer = styled.div`
   margin: 0.25rem 0;
   height: 2.25rem;
   min-height: 2.25rem;
   width: 100%;
   display: flex;
   overflow: hidden;
   height: 100%;
   align-items: center;
`;

const EmptyState = styled.span`
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100%;
   font-size: 1rem;
   font-weight: ${TscStyles.font.weight.semibold};
`;

const MemberInputSubMessage = styled.div<{ theme: TscThemeNames }>`
    margin-top: 0.25rem;
    font-size: 13px;
`;

const emailRegExp = new RegExp(Constants.emailValidationRegexp);

export const MemberInviteContainerBase: React.FC<IMemberInviteContainerProps & IStateMappedProps> = ({labelText, members, isWorking, memberInputSubMessage, membersCount, membersLimit, hideMemberLimit, memberPendingInviteText, removeMember, addMember, onRoleChange, updateWorkerState, theme}) => {
   const {t} = useTranslation();
   const [emailInput, setEmailInput] = useState('');
   const [isValidEmail, setIsValidEmail] = useState(true);
   const loadSpinnerRef = React.useRef(null);
   const showEmptyState = !members.length && !isWorking;
   const memberContainerId = 'email-list-container';
   const [showValidationError, setShouldShowValidationError] = useState(false);

   useEffect(() => {
      isWorking && scrollElementIntoView(loadSpinnerRef.current, memberContainerId);
   }, [isWorking]);
    
   const managers = members.filter((m: { Role: GroupRoleTypes }) => m.Role === GroupRoleTypes.manager);
   const isLastManager = (member: IMemberDetailsWithProfileImageModel) => managers.length === 1 && member.EmailAddress === managers[0].EmailAddress;

   const validateEmailAddress = (inputEmail: string) => emailRegExp.test(inputEmail);

   const onMembersInputChange = (evt: React.ChangeEvent<HTMLInputElement>): void => {
      setEmailInput(evt.target.value);
   };

   useEffect(() => {
      if (emailInput.length === 0) {
         return;
      }

      const emails = emailInput.split(',');
      emails.forEach(e => {
         const validEmail = validateEmailAddress(e);
         if (!validEmail) {
            setShouldShowValidationError(true);
            setIsValidEmail(false);
         } else {
            setShouldShowValidationError(false);
            setIsValidEmail(true);
         }
      });
   }, [emailInput]);
   
   const addEmailsToList = async () => {
      const emails = emailInput.split(',');
      updateWorkerState(true, '');

      let shouldCallback = true;
      let shouldResetToEmpty = true;
       
      for (let emailAddress of emails) {
         emailAddress = emailAddress.trim();
         let addedMember: IUserProfileWithEmailModel;
         try {
            const partialUserProfile = await profileApi.getUserProfileInfoFromEmail(emailAddress);
            addedMember = Object.assign({}, partialUserProfile, {email: emailAddress});
         } catch (e) {
            if (e instanceof UserNotFoundError) {
               addedMember = {
                  email: emailAddress,
                  displayName: emailAddress,
                  profileImageUrl: null,
                  techSmithId: null
               };
            } else {
               logging.error(`Error while attempting to add email: ${emailAddress}`, e);
               updateWorkerState(false, t('general.errorText'));
               shouldCallback = false;
               shouldResetToEmpty = false;
            }
         }
          
         if (shouldCallback) {
            try {
               addMember(addedMember);
            } catch (e) {
               shouldResetToEmpty = false;
               updateWorkerState(false, e.message);
            }
         }
      }
       
      if (shouldResetToEmpty) {
         updateWorkerState(false, '');
         setEmailInput('');
      }
   };
    
   return (
      <>
         <MembersLabelContainer>
            <Label labelText={labelText} />
            {!hideMemberLimit && <MembersCount>{membersCount}/{membersLimit}</MembersCount>}
         </MembersLabelContainer>
         <EmailInputContainer>
            <FlexContainer>
               <TextInput
                  value={emailInput}
                  onChange={onMembersInputChange}
                  themeName={theme}
                  placeholder={t('team.modal.create.membersTextBoxPlaceholder')}
                  required={false}
               />
               {showValidationError && <StyledWarning id="error-message">{t('general.invalidEmailErrorCommaDelimited')}</StyledWarning>}
               {memberInputSubMessage && <MemberInputSubMessage>{memberInputSubMessage}</MemberInputSubMessage>}
            </FlexContainer>
            <ControlButton
               buttonType={TscButtonType.secondary}
               label={t('general.add')}
               onClick={addEmailsToList}
               themeName={theme}
               width={cssConstants.smallButtonWidth}
               disabled={!isValidEmail || isWorking}
               testId="type-ahead-list-manager-add-email-button"
            />
         </EmailInputContainer>
         <EmailListContainer theme={theme} id={memberContainerId}>
            {showEmptyState && <EmptyState>{t('general.noMembers')}</EmptyState>}
            {members.map((m: IMemberDetailsWithProfileImageModel) => <CollectionMemberListItem member={m} onRoleChange={onRoleChange} memberPendingInviteText={memberPendingInviteText} onRemoveClick={removeMember ? () => removeMember(m) : null} key={m.EmailAddress} disableChanges={isLastManager(m)}/>)}
            {isWorking && <LoadingContainer ref={loadSpinnerRef}><Spinner testId="loading-spinner" themeName={theme} /></LoadingContainer>}
         </EmailListContainer>
      </>
   );
};

export interface IMemberInviteContainerProps {
   labelText: string;
   members: IMemberDetailsWithProfileImageModel[];
   isWorking: boolean;
   membersCount: number;
   membersLimit: number;
   memberInputSubMessage: string;
   hideMemberLimit?: boolean;
   memberPendingInviteText: string;
   removeMember?(member: IMemberDetailsWithProfileImageModel): void;
   addMember?(addedMember: IUserProfileWithEmailModel): void;
   onRoleChange?(member: IMemberDetailsWithProfileImageModel, role: GroupRoleTypes): void;
   updateWorkerState?(isWorking: boolean, error: string): void;
}
 
export interface IStateMappedProps {
   theme: TscThemeNames;
}

const mapStatesToProps = (
   {labelText, members, isWorking, memberInputSubMessage, membersCount, membersLimit, hideMemberLimit, memberPendingInviteText, removeMember, addMember, onRoleChange, updateWorkerState}: IMemberInviteContainerProps,
   {theme}: Partial<IStateMappedProps>
): IStateMappedProps & IMemberInviteContainerProps => ({labelText, members, isWorking, memberInputSubMessage, membersCount, membersLimit, hideMemberLimit, memberPendingInviteText, removeMember, addMember, onRoleChange, updateWorkerState, theme});
 
export default withMemoizedContexts(mapStatesToProps, themeStore)(MemberInviteContainerBase);

import styled from 'styled-components';
import {TscStyles, TscButtonGlyphPlacement, TscButtonGlyphPlacements, TscThemeName, TscThemeNames} from '@techsmith/tsc-cloud-style-guide';
import cssConstants from '../../constants/cssConstants';

export const NonStyledButton = styled.button`
   margin: 0;
   padding: 0;
   border: 0;
   background: transparent;
   font-size: 1em;
   cursor: pointer;
   ::-moz-focus-inner {
      padding : 0;
      border  : 0;
   }
`;

export const MenuButton = styled(NonStyledButton)`
   font-size: 0.875rem;
   color: ${TscStyles.color.text.mediumDark};
   fill: ${TscStyles.color.text.mediumDark};
   letter-spacing: 0.6px;
   text-decoration: none;
   width: 100%;
   height: 100%;
   padding: 0.5rem;
   text-align: left;
   display: flex;
   align-items: center;
`;

export const ModalOptionsContainer = styled.ul`
   padding: 0;
   margin: 0;

   &.modal-menu {
      display: flex;
      flex-direction: column;

      button {
         display: flex;
         align-items: center;
         height: 2.75rem;
         padding-left: 1rem;
         border-radius: 0;
         text-transform: uppercase;

         > span:nth-of-type(2) {
            margin-left: 1rem;
         }
      }
   }
`;

export const StyledWarning = styled.div`
   margin: 0 0 .55rem 0;
   font-size: .875rem;
   font-weight: 600;
   color: ${TscStyles.color.message.warning};
   overflow: hidden;
   text-overflow: ellipsis;
`;

export const HyperLinkButton = styled(NonStyledButton)`
   text-decoration: underline;
   color: ${TscStyles.color.message.info};
   cursor: pointer;
   padding: 0 0.25rem;
`;

export const GradientBorder = styled.div`
   z-index: ${cssConstants.zIndex.viewPageElement};
   height: 1px;
   width: 100%;
   background: ${cssConstants.brandGradient};
`;

export const ButtonStyledAsAnchor = styled(NonStyledButton)`
   color:inherit;
   text-decoration:underline;

   &:focus, &:hover{
      text-decoration:none;
      outline-style:dashed;
      outline-width:1px;
      outline-offset:2px;
   }

   &:active{
      text-decoration:none;
      outline-style: solid;
      outline-width:1px;
   }
`;

export const StyledAnchor = styled.a`
   color: inherit;
   text-decoration: underline;
   border: 1px dashed transparent;
   outline: none;

   &:focus, &:hover {
      text-decoration: none;
      border-color: currentColor;
   }

   &:active {
      text-decoration: none;
      border-color: currentColor;
      border-style: solid;
   }
`;

export const MinWidthButtonWrapper = styled.div`
   button {
      min-width: ${cssConstants.defaultButtonWidth};
   }
`;

export const GridLayoutButtonGroup = styled.div`
   display: grid;
   grid-template-columns: auto auto auto auto;
   grid-gap: .5rem;
   flex-shrink: 0;
`;

export const HideButtonText = styled.div<{breakpointWidth: number; glyphPlacement: TscButtonGlyphPlacements}>`

   @media screen and (max-width: ${props => props.breakpointWidth}px) {
      button {
         width: 2rem;
         display: flex;
         align-items: center;
         justify-content: center;

         span:nth-child(${props => props.glyphPlacement === TscButtonGlyphPlacement.left ? 2 : 1}) {
            display: none;
         }
      }
   }
`;

export const SearchBarContainer = styled.div<{hasFocus: boolean; theme: TscThemeNames}>`
   border-radius: ${TscStyles.border.radius.md};
   background: ${props => props.theme === TscThemeName.dusk ? TscStyles.color.ui.dusk.mediumDark : TscStyles.color.ui.dawn.lightMedium};
   display: flex;
   align-items: center;
   justify-content: right;
   position: relative;
   transition: background 0.3s ease-in-out;
   margin: 0;
`;

export const SearchBarInput = styled.input<{hasFocus: boolean; theme: TscThemeNames}>`
   background: none;
   color: ${props => props.theme === TscThemeName.dusk ? TscStyles.color.text.light : TscStyles.color.text.dark};
   outline: none;
   border: none;
   padding-left: ${props => props.hasFocus ? '0.5rem' : 0};
   width: ${props => props.hasFocus ? '100%' : 0};
   transition: all 0.3s ease-in-out;
   right: 0;

   /* Prevent iOS mobile auto zoom on textarea focus */
   @supports (-webkit-overflow-scrolling: touch) {
      font-size: 1rem;
   }
`;

export const SearchForm = styled.form`
   display: flex;
   margin-left: .5rem;
`;

export const CommentFooterWrapper = styled.div<{isReply: boolean; isEditing: boolean; isCommentOwner?: boolean}>`
   margin-top: 0.5rem;
   margin-left: -0.5rem;
   display: flex;
   justify-content: flex-start;
   min-height: 1rem;

   button {
      font-size: .75rem;
      height: 1.5rem;
      line-height: 1rem;
      padding: 0 .5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: ${props => props.isEditing ? '0.5rem' : '0'};
      letter-spacing: 0;
   }
`;

export const MenuList = styled.ul`
   padding: 0;
`;

export const ScreenReaderOnly = styled.span`
   border: 0;
   clip: rect(0 0 0 0);
   height: 1px;
   margin: -1px;
   overflow: hidden;
   padding: 0;
   position: absolute;
   width: 1px;
`;

export const AriaAlert = styled(ScreenReaderOnly)`
   display: none;
`;

export const PageWrapper = styled.div<{theme: TscThemeNames}>`
   font-family: ${TscStyles.font.family.primary};
   display: flex;
   flex-direction: column;
   background: ${props => props.theme === TscThemeName.dusk ? TscStyles.color.ui.dusk.dark : cssConstants.dawnPageBackgroundColor};
   min-height: 100svh;
   position: relative;
   z-index: 0;
`;

export const ContentArea = styled.div`
   display: flex;
   flex-direction: row;
   width: 100%;
`;

export const SidebarContainer = styled.div`
   position: sticky;
   height: calc(100vh - var(${cssConstants.variables.headerHeight}, ${cssConstants.headerHeight}));
   top: var(${cssConstants.variables.headerHeight}, ${cssConstants.headerHeight});

   @media screen and (max-width: ${cssConstants.headerBreakpoint}px){
     display: none;
   }
`;

export const LibraryContentWrapper = styled.div<{theme: TscThemeNames; isSidebarAbsolutePositioned: boolean}>`
   flex: 1 1 auto;
   box-sizing: border-box;
   max-width: ${props => props.isSidebarAbsolutePositioned ? '100%' : `calc(100% - ${cssConstants.sidebarWidth})`};
   background: ${props => props.theme === TscThemeName.dusk ? TscStyles.color.ui.dusk.dark : cssConstants.dawnPageBackgroundColor};
`;

export const LibraryContentContainer = styled.div`
   margin-top: 1rem;
   padding: 0 1rem;

   .toggleShowQuotaVideo {
      width: fit-content;
      margin-right: .5rem;
   }

   .spinner-container {
      margin: 2rem auto 1rem auto;
   }
`;

export const GridLayoutContainer = styled.div<{hasMedia: boolean}>`
   display: ${props => props.hasMedia ? 'grid' : 'flex'};
   grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
   grid-gap: 1rem;
   align-items: flex-start;
   justify-content: center;
   margin-bottom: 1rem;
`;

export const CollapsableContent = styled.div<{isCollapsed: boolean}>`
   display: ${props => props.isCollapsed ? 'none' : 'block'};
`;

export const Subheader = styled.div<{theme: TscThemeNames; isVisible: boolean}>`
   display: ${props => props.isVisible ? 'flex' : 'none'};
   align-items: center;
   position: sticky;
   top: var(${cssConstants.variables.headerHeight}, ${cssConstants.headerHeight});
   min-height: ${cssConstants.subHeaderHeight};
   padding: .5rem 1rem;
   z-index: ${cssConstants.zIndex.subHeader};
   background-color: ${props => props.theme === TscThemeName.dusk ? TscStyles.color.ui.dusk.medium : TscStyles.color.ui.dawn.light};
   box-shadow: 0 2px 6px rgba(0,0,0,.15);
   max-width: 100%;

   @media screen and (max-width: ${cssConstants.headerBreakpoint}px) {
      left: 0;
   }
`;

export const SubheaderTitle = styled.h3`
   margin-left: 0;
`;

export const SubheaderControls = styled.div<{theme: TscThemeNames}>`
   height: ${cssConstants.subHeaderHeight};
   flex: 1 1 auto;
   display: flex;
   align-items: center;
   justify-content: space-between;
   color: ${props => props.theme === TscThemeName.dusk ? TscStyles.color.text.lightMedium : TscStyles.color.text.medium};
   max-width: 100%;
`;

export const MediaSectionHeader = styled.div<{theme: TscThemeNames}>`
   display: flex;
   margin-top: .5rem;
   padding: .5rem;
   align-items: center;
   justify-content: space-between;
   color: ${props => props.theme === TscThemeName.dusk ? TscStyles.color.text.light : TscStyles.color.text.dark};

   .showQuotaVideoLabel {
      font-size: 0.875rem;
      line-height: 1.4;
   }

   @media screen and (max-width: ${cssConstants.mobileLibrarySectionMenuBreakPoint}px) {
      margin: 1rem 0;

      &:has(.filterVideoContent) {
         flex-wrap: wrap;

         .mediaSectionHeaderLabel {
            width: 100%;
         }

         .filterVideoContent {
            margin: .75rem 0;
         }
      }
   }
`;

export const SubheaderTitleContainer = styled.div<{theme: TscThemeNames}>`
   display: flex;
   align-items: center;
   overflow: hidden;
   height: 3.75rem;

   > svg {
      flex-shrink: 0;
      margin-right: .5rem;
      width: 1.5rem;
      height: 1.5rem;
      fill: ${props => props.theme === TscThemeName.dusk ? TscStyles.color.text.lightMedium : TscStyles.color.text.medium};
   }
`;

export const SimpleList = styled.ul`
   list-style: none;
   padding-left: 0;
`;

import React, {useEffect, useState} from 'react';
import {INavigationTab} from '../tabs/Tabs';
import styled from 'styled-components';
import {
   ControlButton,
   Glyphs,
   PopoverButton,
   TscButtonType,
   TscPopoverOrigin,
   TscStyles,
   TscThemeName
} from '@techsmith/tsc-cloud-style-guide';
import {useTranslation} from 'react-i18next';
import Constants, {GroupRoleTypes, EntryPoint, NavigationTabTypes} from '../../constants/Constants';
import config from '../../service/config';
import CreateCollectionModal from '../modals/CreateCollectionModal';
import CreateOrRenameFolderModal from '../modals/CreateFolderModal';
import FolderMoreMenu from '../details/FolderMoreMenu';
import CollectionMoreMenu from '../details/CollectionMoreMenu';
import cssConstants from '../../constants/cssConstants';
import withMemoizedContexts from '../../context/contextContainerHoC';
import MenuRow, {MenuRowButton} from './MenuRow';
import {
   clearActiveCollection,
   clearActiveCollectionAndUpdateRouteToMyCollections,
   clearActiveCollectionAndUpdateRouteToMyFeed,
   collectionsStore,
   updateActiveCollection,
   updateSidebarCollections
} from '../../context/collectionsProvider';

import {
   clearActiveTeam,
   teamsStore,
   updateUserTeamsWithFolderTrees
} from '../../context/teamsProvider';
import {EnvironmentType, ICollectionModel, ITeamModel, ITeamModelFolderTree} from '../../model/appModel';
import {IFolderTreeNode} from '../../model/folderTreeNode';
import {
   clearActiveFolder,
   foldersStore,
   updateActiveFolder,
   updateActiveFolderToSpecial,
   updateFoldersData
} from '../../context/foldersProvider';
import {useLocation} from 'react-router';
import {IContentBatchList} from '../../model/draggableLibraryContent';
import folderApi from '../../service/folderApi';
import collectionApi from '../../service/collectionApi';
import {removeLibraryMedia} from '../../context/libraryProvider';
import {IMediaDetails} from '../../model/mediaDetails';
import {DragAndDropItemTypes} from '../../model/dragAndDropItemTypes';
import ContentDropTarget from '../content/ContentDropTarget';
import {BatchContentWarningModalType} from '../modals/BatchContentWarningModal';
import toast from '../../service/toast';
import logging from '../../service/logging';
import {getWellKnownFolderPermalink, isWellKnownFolder, IWellKnownFolder} from '../../constants/wellKnownFolder';
import {migrationStore} from '../../context/migrationProvider';
import FeedGlyph16x16 from '../../static/svg/FeedGlyph16x16.svg';
import teamApi from '../../service/teamApi';
import TeamMoreMenu from '../details/TeamMoreMenu';
import CreateTeamModal from '../modals/CreateTeamModal';

const showMoreListLength = 8;
// navbar currently does not change themes, when it does this will be dynamic
const navBarThemeTextColor = TscStyles.color.text.light;

const ItemCountContainer = styled.div`
   display: flex;
   flex: none;
   justify-content: center;
   align-items: center;
   color: ${navBarThemeTextColor};
`;

const MigrationMessage = styled.div`
   font-weight: 600;
   text-align: center;
   line-height: 24px;
   white-space: normal;
   color: ${TscStyles.color.text.lightMedium};
   margin: 1rem .5rem 0 .5rem;
`;

const ShowMoreButtonContainer = styled.li`
   position: relative;
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   height: 40px;
   padding: 0 0.5rem;
   font-size: .85rem;
`;

const Divider = styled.div`
   height: 1px;
   background-color: ${TscStyles.color.ui.dusk.medium};
   margin: 0 0 0 34px;
`;

// cant use `styled` here because this is passed to 'PopoverButton' 'style' prop
const menuControlButtonStyle = {
   width: '1.5rem',
   height: '1.5rem',
   display: 'flex',
   flex: 'none',
   justifyContent: 'center',
   alignItems: 'center'
};

const onWellKnownFolderClick = (e: React.MouseEvent<HTMLButtonElement>, specialFolder: IWellKnownFolder) => {
   e.preventDefault();
   clearActiveCollection();
   updateActiveFolderToSpecial(specialFolder);
};

const onFolderClick = (e: React.MouseEvent<HTMLButtonElement>, folder: IFolderTreeNode) => {
   e.preventDefault();
   clearActiveCollection();
   updateActiveFolder(folder, config.teamConfig?.teamId);
};

const onCollectionClick = async (e: React.MouseEvent<HTMLButtonElement>, collection: ICollectionModel) => {
   e.preventDefault();
   clearActiveFolder();
   clearActiveTeam();
   if (collection) {
      await updateActiveCollection(collection);
      clearActiveFolder();
      clearActiveTeam();
   } else {
      clearActiveCollectionAndUpdateRouteToMyCollections();
   }
};

const onMyCollectionsClick = (e: React.MouseEvent<HTMLButtonElement>) => {
   e.preventDefault();
   clearActiveFolder();
   clearActiveTeam();
   clearActiveCollectionAndUpdateRouteToMyCollections();
};

const onFollowedCollectionsClick = (e: React.MouseEvent<HTMLButtonElement>) => {
   e.preventDefault();
   clearActiveFolder();
   clearActiveTeam();
   clearActiveCollectionAndUpdateRouteToMyFeed();
};

export const SidebarContentBase: React.FC<ISidebarContentProps & IStateMappedProps> = ({tabs, activeCollection, sidebarCollections, userTeamsFolderTree, activeFolder, activeTeam, personalFolderTree, teamFolderTree, migrationInProgress}) => {
   const {t} = useTranslation();
   const [showFullFeedList, setShowFullFeedList] = useState(false);
   const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);
   const [showCreateTeamModal, setShowCreateTeamModal] = useState(false);
   const [showCreateCollectionModal, setShowCreateCollectionModal] = useState(false);
   const [droppedContent, setDroppedContent] = useState<IContentBatchList>(null);
   const location = useLocation();

   useEffect(() => {
      !!sidebarCollections.length || updateSidebarCollections();
      !!userTeamsFolderTree?.length || updateUserTeamsWithFolderTrees();
      teamFolderTree && config.teamConfig || updateFoldersData(config.libraryConfig?.canAdministerProfile ? config.libraryConfig?.profileTechSmithId ?? config.user.TechSmithId : config.user.TechSmithId, config.teamConfig?.teamId);
      personalFolderTree && config.libraryConfig || updateFoldersData(config.libraryConfig?.canAdministerProfile ? config.libraryConfig?.profileTechSmithId ?? config.user.TechSmithId : config.user.TechSmithId);
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [/* This should remain empty because we only want this to trigger on initial load */]);

   // the reason for this ref, and the corresponding useEffect is complicated, but 'activeFolder' wasn't being properly updated
   // because of how it is hooked into the drag/drop hook callbacks via instantiated func that was keeping a handle on the original
   // to be this more properly, this component should probably be restructured to _not_ re-render nearly as often as it is by leveraging
   // hooks better.  there was not time to make this change as a final bugfix when applying new filters (JMD: 2022-09)
   const activeFolderRef = React.useRef(activeFolder);
   React.useEffect(() => {
      activeFolderRef.current = activeFolder;
   }, [activeFolder]);

   const getRightControlCreate = (type: NavigationTabTypes): React.JSX.Element => {
      let enableDrop = false;
      let warningModalType = BatchContentWarningModalType.CreateMove;
      let action: () => void = null;
      let isValidMedia: (content: IMediaDetails) => boolean = null;
      let isValidFolder: (content: IFolderTreeNode) => boolean = null;
      let drop: (content: IContentBatchList) => Promise<void> = null;
      let title: string = null;
      let createButtonTestClass: string = null;

      if (type === NavigationTabTypes.myFolders || type === NavigationTabTypes.teams) {
         const isMyFoldersTabType = type === NavigationTabTypes.myFolders;

         enableDrop = config.libraryConfig?.canAdministerProfile || config.teamConfig?.loggedInUserHasCreatePermission; // can only create folder + drop your own media
         warningModalType = BatchContentWarningModalType.CreateMove;
         action = isMyFoldersTabType ? () => setShowCreateFolderModal(true) : () => setShowCreateTeamModal(true);
         title = isMyFoldersTabType ? t('folders.options.createButtonTitle') : t('team.modal.create.createTeamButtonLabel');
         createButtonTestClass = 't-folders-create-button';
         isValidMedia = () => true;
         isValidFolder = content => {
            // you cant drag the root folder, so we only care about 1 level deep for source
            const sourceHasNoSubFolders = (content.SubFolders?.length || 0) === 0;
            return sourceHasNoSubFolders;
         };
         drop = async (content: IContentBatchList) => {
            setShowCreateFolderModal(true);
            setDroppedContent(content);
            return Promise.resolve();
         };
      }
      if (type === NavigationTabTypes.myCollections || type === NavigationTabTypes.allCollections) {
         enableDrop = true; // can create collection and drop any valid media, it will create copy if not your media
         warningModalType = BatchContentWarningModalType.CreateAddToCollection;
         action = () => setShowCreateCollectionModal(true);
         title = t('collections.options.createButtonTitle');
         createButtonTestClass = 't-collections-create-button';
         isValidMedia = () => true;
         isValidFolder = () => false;
         drop = (content: IContentBatchList) => {
            setShowCreateCollectionModal(true);
            setDroppedContent(content);
            return Promise.resolve();
         };
      }

      if (!action) {
         return null;
      }

      if (activeFolder?.ParentFolder && type === NavigationTabTypes.myFolders) {
         return null;
      }

      const rightControlButton = (
         <ControlButton
            onClick={action}
            glyph={<Glyphs.Add16x16 />}
            title={title}
            buttonType={TscButtonType.tertiary}
            themeName={TscThemeName.dusk}
            testId={createButtonTestClass}
         />
      );

      return (<>
         { enableDrop ?
            <div>
               <ContentDropTarget
                  accept={[DragAndDropItemTypes.Media, DragAndDropItemTypes.Folder, DragAndDropItemTypes.Mixed]}
                  isValidMedia={isValidMedia}
                  isValidFolder={isValidFolder}
                  onDrop={drop}
                  warningModalType={warningModalType}>
                  <MenuRowButton>{rightControlButton}</MenuRowButton>
               </ContentDropTarget>
            </div>
            : <MenuRowButton>{rightControlButton}</MenuRowButton>
         }
      </>
      );
   };

   const isMovableMedia = (media: IMediaDetails, destination: IFolderTreeNode) => media && media?.ParentFolder?.Hash !== destination?.Hash;
   const isMovableFolder = (source: IFolderTreeNode, destination: IFolderTreeNode) => {
      // you cant drag the root folder, so we only care about 1 level deep for source
      const sourceHasNoSubFolders = (source.SubFolders?.length || 0) === 0;
      // if destination has a parent folder, it is a nested subfolder, a root-level folder 'parent' is null
      const destinationIsNestedSubFolder = !!destination?.ParentFolder;
      const destinationIsRoot = !destination;
      // source should always be an object, dest may be 'null' for mylibrary level
      const destinationIsSelf = !!(destination && source.Hash === destination.Hash);
      const destinationIsSame = source.ParentFolder?.Hash === destination?.Hash;

      return !destinationIsSelf && !destinationIsSame && !destinationIsNestedSubFolder && (destinationIsRoot || sourceHasNoSubFolders);
   };

   const onMoveToFolder = async (target: IFolderTreeNode, content: IContentBatchList) => {
      const mediaList: IMediaDetails[] = content.media ?? [];
      const folderList: IFolderTreeNode[] = content.folders ?? [];

      try {
         await folderApi.updateParentFolder(mediaList.map(media => media.Hash), folderList.map(folder => folder.Hash), target?.Hash, config.libraryConfig?.profileTechSmithId ?? config.user.TechSmithId, config.teamConfig?.teamId);
         await updateFoldersData(config.libraryConfig?.profileTechSmithId ?? config.user.TechSmithId, config.teamConfig?.teamId);
         if (!isWellKnownFolder(activeFolderRef.current?.Hash)) {
            removeLibraryMedia(mediaList);
         }

         toast.displayMoveToFolderSuccess(mediaList, folderList, target?.Name);
      } catch (e) {
         logging.warn(`Error encountered moving items to ${target?.Name}`, e);
         toast.displayError(t('folders.share.error'));
      }
   };

   const onAddToCollection = async (target: ICollectionModel, content: IContentBatchList) => {
      const mediaList = content.media ?? [];

      try {
         await collectionApi.addToCollection(target.Id, mediaList.map(media => media.Hash));
         // you shouldn't be able to drag folders to collections, so nothing to do for folders

         toast.displayAddToCollectionsSuccess(target.Name);
      } catch (e) {
         logging.warn(`Error encountered adding items to collection ${target.Name}`, e);
         toast.displayError(t('collections.share.error'));
      }
   };

   const onMoveToTeam = async (target: ITeamModel, content: IContentBatchList): Promise<void> => {
      const mediaList = content.media ?? [];
      try {
         await teamApi.moveMedia(target.TeamId, {MediaHashes: mediaList.map(m => m.Hash), ToTeamLibrary: true});
         removeLibraryMedia(mediaList);
         toast.displayAddToCollectionsSuccess(target.Name);
      } catch (e) {
         logging.warn(`Error encountered adding items to team ${target.Name}`, e);
         toast.displayError(t('collections.share.error'));
      }
   };

   const closeFolderModal = (folder: IFolderTreeNode) => {
      setShowCreateFolderModal(false);

      if (droppedContent && folder) {
         void onMoveToFolder(folder, droppedContent);
         setDroppedContent(null);
      }
   };

   const closeCollectionModal = (collection: any) => {
      setShowCreateCollectionModal(false);

      if (droppedContent && collection) {
         void onAddToCollection(collection, droppedContent);
         setDroppedContent(null);
      }
   };

   const collectionsToManage = sidebarCollections?.filter(g => g.Role !== GroupRoleTypes.participant) ?? [];

   const myFoldersSubMenu = (nestingLevel: number, enableDnD: boolean = true) => {
      const myRecentMediaRow = (<MenuRow
         key={'navMenu.recentMedia'}
         fragmentKey={'navMenu.recentMedia'}
         label={t('sidebar.recentMedia')}
         link={getWellKnownFolderPermalink(IWellKnownFolder.recentMedia)}
         onClick={config.entryPoint === EntryPoint.library ? e => onWellKnownFolderClick(e, IWellKnownFolder.recentMedia) : null}
         glyph={<Glyphs.Time16x16 />}
         highlightRow={IWellKnownFolder.recentMedia === activeFolder?.Hash}
         nestingLevel={nestingLevel}
      />);

      const myVideosRow = (<MenuRow
         key={'navMenu.allVideo'}
         fragmentKey={'navMenu.allVideo'}
         label={t('sidebar.allVideos')}
         link={getWellKnownFolderPermalink(IWellKnownFolder.allVideo)}
         onClick={config.entryPoint === EntryPoint.library ? e => onWellKnownFolderClick(e, IWellKnownFolder.allVideo) : null}
         glyph={<Glyphs.Video16x16 />}
         highlightRow={IWellKnownFolder.allVideo === activeFolder?.Hash}
         nestingLevel={nestingLevel}
      />);

      const myImagesRow = (<MenuRow
         key={'navMenu.allImages'}
         fragmentKey={'navMenu.allImages'}
         label={t('sidebar.allImages')}
         link={getWellKnownFolderPermalink(IWellKnownFolder.allImage)}
         onClick={config.entryPoint === EntryPoint.library ? e => onWellKnownFolderClick(e, IWellKnownFolder.allImage) : null}
         glyph={<Glyphs.Image16x16 />}
         highlightRow={IWellKnownFolder.allImage === activeFolder?.Hash}
         nestingLevel={nestingLevel}
      />);

      const divider = (<Divider key={'navMenu.divider'} />);

      return ( // we only concat the 'RecentMedia', 'AllVideos' and 'AllImages' filter onto the owning users list
         config.libraryConfig?.loggedInUserIsProfileOwner || config.feedConfig?.loggedInUserIsProfileOwner || config.teamConfig ? [
            myRecentMediaRow,
            myVideosRow,
            myImagesRow,
            divider
         ] : []
      ).concat((personalFolderTree?.SubFolders ?? []).map(folder => {
         const enableDrag = enableDnD && config.libraryConfig?.canAdministerProfile;
         const enableDrop = enableDnD && config.libraryConfig?.canAdministerProfile;

         const rightControl = config.sidebarEnableContextMenus ?
            (<MenuRowButton>
               <PopoverButton
                  origin={TscPopoverOrigin.bottomRight}
                  buttonType={TscButtonType.tertiary}
                  themeName={TscThemeName.dusk}
                  buttonGlyph={<Glyphs.EllipsisStacked16x16/>}
                  buttonTitle={t('folders.options.buttonTitle')}
                  mobileContextMenuTitle={t('folders.options.title', {folderName: folder.Name})}
                  popoverMenu={<FolderMoreMenu folder={folder} />}
                  style={menuControlButtonStyle}
                  useMobileMenu={true}
                  mobileContextMenuZindex={cssConstants.zIndex.mobileContextMenu}
                  closeMenuOnClick={true}
                  popoverMenuMaxHeight={cssConstants.contextMenuMaxHeight}
                  testId={`navMenuRow.folderContext.${folder.Hash}`}
               />
            </MenuRowButton>) : null
         ;

         const folderMenuRowSubMenu = (folder.SubFolders ?? []).map(subFolder => {
            const subFolderRightControl = config.sidebarEnableContextMenus ?
               (<MenuRowButton>
                  <PopoverButton
                     origin={TscPopoverOrigin.bottomRight}
                     buttonType={TscButtonType.tertiary}
                     themeName={TscThemeName.dusk}
                     buttonGlyph={<Glyphs.EllipsisStacked16x16/>}
                     buttonTitle={t('folders.options.buttonTitle')}
                     mobileContextMenuTitle={t('folders.options.title', {folderName: subFolder.Name})}
                     popoverMenu={<FolderMoreMenu folder={subFolder} />}
                     style={menuControlButtonStyle}
                     useMobileMenu={true}
                     mobileContextMenuZindex={cssConstants.zIndex.mobileContextMenu}
                     closeMenuOnClick={true}
                     popoverMenuMaxHeight={cssConstants.contextMenuMaxHeight}
                     testId={`navMenuRow.subFolderContext.${subFolder.Hash}`}
                  />
               </MenuRowButton>) : null
            ;

            return (<MenuRow
               key={`navMenu.subFolder.${subFolder.Hash}`}
               fragmentKey={`navMenuRow.subFolder.${subFolder.Hash}`}
               label={subFolder.Name}
               link={`/folder/${subFolder.Hash}`}
               onClick={config.entryPoint === EntryPoint.library ? e => onFolderClick(e, subFolder) : null}
               glyph={<Glyphs.Folder16x16 />}
               highlightRow={subFolder.Hash === activeFolder?.Hash}
               nestingLevel={nestingLevel + 1}
               controlButton={subFolderRightControl}
               dragInfo={enableDrag && {
                  type: DragAndDropItemTypes.Folder,
                  item: subFolder
               }}
               dropInfo={enableDrop && {
                  isValidMedia: (content: IMediaDetails) => isMovableMedia(content, subFolder),
                  isValidFolder: (content: IFolderTreeNode) => isMovableFolder(content, subFolder),
                  onDrop: content => onMoveToFolder(subFolder, content),
                  warningModalType: BatchContentWarningModalType.Move
               }}
            />);
         });

         return (<MenuRow
            key={`navMenu.folder.${folder.Hash}`}
            fragmentKey={`navMenuRow.folder.${folder.Hash}`}
            label={folder.Name}
            link={`/folder/${folder.Hash}`}
            onClick={config.entryPoint === EntryPoint.library ? e => onFolderClick(e, folder) : null}
            glyph={<Glyphs.Folder16x16 />}
            nestingLevel={nestingLevel}
            highlightRow={folder.Hash === activeFolder?.Hash}
            controlButton={rightControl}
            subMenu={folderMenuRowSubMenu}
            accordionDefinition={{
               isInitiallyExpanded: false
            }}
            dragInfo={enableDrag && {
               type: DragAndDropItemTypes.Folder,
               item: folder
            }}
            dropInfo={enableDrop && {
               isValidMedia: (content: IMediaDetails) => isMovableMedia(content, folder),
               isValidFolder: (content: IFolderTreeNode) => isMovableFolder(content, folder),
               onDrop: content => onMoveToFolder(folder, content),
               warningModalType: BatchContentWarningModalType.Move
            }}
         />);
      }));
   };

   const teamFoldersSubMenu = (teamId: string, nestingLevel: number, enableDnD: boolean = true) =>
      (teamFolderTree?.SubFolders ?? []).map(folder => {
         const enableDrag = enableDnD && config.teamConfig?.loggedInUserHasCreatePermission;
         const enableDrop = enableDnD && config.teamConfig?.loggedInUserHasCreatePermission;

         const rightControl = config.sidebarEnableContextMenus ?
            (<MenuRowButton>
               <PopoverButton
                  origin={TscPopoverOrigin.bottomRight}
                  buttonType={TscButtonType.tertiary}
                  themeName={TscThemeName.dusk}
                  buttonGlyph={<Glyphs.EllipsisStacked16x16/>}
                  buttonTitle={t('folders.options.buttonTitle')}
                  mobileContextMenuTitle={t('folders.options.title', {folderName: folder.Name})}
                  popoverMenu={<FolderMoreMenu folder={folder} />}
                  style={menuControlButtonStyle}
                  useMobileMenu={true}
                  mobileContextMenuZindex={cssConstants.zIndex.mobileContextMenu}
                  closeMenuOnClick={true}
                  popoverMenuMaxHeight={cssConstants.contextMenuMaxHeight}
                  testId={`navMenuRow.folderContext.${folder.Hash}`}
               />
            </MenuRowButton>) : null
         ;

         const folderMenuRowSubMenu = (folder.SubFolders ?? []).map(subFolder => {
            const subFolderRightControl = config.sidebarEnableContextMenus ?
               (<MenuRowButton>
                  <PopoverButton
                     origin={TscPopoverOrigin.bottomRight}
                     buttonType={TscButtonType.tertiary}
                     themeName={TscThemeName.dusk}
                     buttonGlyph={<Glyphs.EllipsisStacked16x16/>}
                     buttonTitle={t('folders.options.buttonTitle')}
                     mobileContextMenuTitle={t('folders.options.title', {folderName: subFolder.Name})}
                     popoverMenu={<FolderMoreMenu folder={subFolder} />}
                     style={menuControlButtonStyle}
                     useMobileMenu={true}
                     mobileContextMenuZindex={cssConstants.zIndex.mobileContextMenu}
                     closeMenuOnClick={true}
                     popoverMenuMaxHeight={cssConstants.contextMenuMaxHeight}
                     testId={`navMenuRow.subFolderContext.${subFolder.Hash}`}
                  />
               </MenuRowButton>) : null
            ;

            return (<MenuRow
               key={`navMenu.subFolder.${subFolder.Hash}`}
               fragmentKey={`navMenuRow.subFolder.${subFolder.Hash}`}
               label={subFolder.Name}
               link={`/teams/${teamId}/folder/${subFolder.Hash}`}
               onClick={config.entryPoint === EntryPoint.teams ? e => onFolderClick(e, subFolder) : null}
               glyph={<Glyphs.Folder16x16 />}
               highlightRow={subFolder.Hash === activeFolder?.Hash}
               nestingLevel={nestingLevel + 1}
               controlButton={subFolderRightControl}
               dragInfo={enableDrag && {
                  type: DragAndDropItemTypes.Folder,
                  item: subFolder
               }}
               dropInfo={enableDrop && {
                  isValidMedia: (content: IMediaDetails) => isMovableMedia(content, subFolder),
                  isValidFolder: (content: IFolderTreeNode) => isMovableFolder(content, subFolder),
                  onDrop: content => onMoveToFolder(subFolder, content),
                  warningModalType: BatchContentWarningModalType.Move
               }}
            />);
         });

         return (<MenuRow
            key={`navMenu.folder.${folder.Hash}`}
            fragmentKey={`navMenuRow.folder.${folder.Hash}`}
            label={folder.Name}
            link={`/teams/${teamId}/folder/${folder.Hash}`}
            onClick={config.entryPoint === EntryPoint.teams ? e => onFolderClick(e, folder) : null}
            glyph={<Glyphs.Folder16x16 />}
            nestingLevel={nestingLevel}
            highlightRow={folder.Hash === activeFolder?.Hash}
            controlButton={rightControl}
            subMenu={folderMenuRowSubMenu}
            accordionDefinition={{
               isInitiallyExpanded: false
            }}
            dragInfo={enableDrag && {
               type: DragAndDropItemTypes.Folder,
               item: folder
            }}
            dropInfo={enableDrop && {
               isValidMedia: (content: IMediaDetails) => isMovableMedia(content, folder),
               isValidFolder: (content: IFolderTreeNode) => isMovableFolder(content, folder),
               onDrop: content => onMoveToFolder(folder, content),
               warningModalType: BatchContentWarningModalType.Move
            }}
         />);
      });

   const myCollectionsSubMenu = (nestingLevel: number, enableDnD: boolean = true) => {
      if (migrationInProgress) {
         return [<MigrationMessage className="migration-message" key="migration-message">{t('collections.inMigration')}</MigrationMessage>];
      }

      return collectionsToManage.map(collection => {
         const rightControl = config.sidebarEnableContextMenus ?
            (<MenuRowButton>
               <PopoverButton
                  origin={TscPopoverOrigin.topRight}
                  buttonType={TscButtonType.tertiary}
                  themeName={TscThemeName.dusk}
                  buttonGlyph={<Glyphs.EllipsisStacked16x16/>}
                  buttonTitle={t('collections.options.buttonTitle')}
                  mobileContextMenuTitle={t('collections.options.title', {collectionName: collection.Name})}
                  popoverMenu={<CollectionMoreMenu collection={collection} hideShareOption={false} />}
                  style={menuControlButtonStyle}
                  useMobileMenu={true}
                  mobileContextMenuZindex={cssConstants.zIndex.mobileContextMenu}
                  closeMenuOnClick={true}
                  popoverMenuMaxHeight={cssConstants.contextMenuMaxHeight}
                  testId={`navMenuRow.myCollectionContext.${collection.Id}`}
               />
            </MenuRowButton>) : null;

         // TODO enabled the onclick for all environments when management view is complete by removing this variable and the reference to it below
         const onClickEnabled = config.environmentData.IsRunningLocally || config.environmentData.Environment.toLowerCase() === EnvironmentType.dev.toLowerCase();

         return (<MenuRow
            key={`navMenu.myCollection.${collection.Id}`}
            fragmentKey={`navMenuRow.myCollection.${collection.Id}`}
            label={collection.Name}
            link={`${Constants.navigation.manageCollectionBasePath}/${collection.Id}`}
            onClick={onClickEnabled && config.entryPoint === EntryPoint.library ? e => onCollectionClick(e, collection) : null}
            highlightRow={collection.Id === activeCollection?.Id}
            glyph={<Glyphs.Collection16x16 />}
            nestingLevel={nestingLevel}
            controlButton={rightControl}
            accordionDefinition={{
               noPlaceholder: true
            }}
            dropInfo={enableDnD && config.libraryConfig?.canAdministerProfile && {
               isValidMedia: () => true,
               isValidFolder: () => false,
               onDrop: content => onAddToCollection(collection, content),
               warningModalType: BatchContentWarningModalType.AddToCollection
            }}
         />);
      });
   };

   const followedCollectionSubMenu = (nestingLevel: number) => {
      if (migrationInProgress) {
         return [<MigrationMessage className="migration-message" key="migration-message">{t('collections.inMigration')}</MigrationMessage>];
      }
      let followedCollections = (sidebarCollections ?? []).filter(collection => collection.Role === GroupRoleTypes.participant).map(collection => {
         const rightControl = <ItemCountContainer>{collection.MediaCount}</ItemCountContainer>;

         return (<MenuRow
            key={`navMenu.myFeed.${collection.Id}`}
            fragmentKey={`navMenuRow.myFeed.${collection.Id}`}
            label={collection.Name}
            link={`${Constants.navigation.collectionsViewBasePath}/${collection.Id}`}
            onClick={config.entryPoint === EntryPoint.feed || config.entryPoint === EntryPoint.library ? e => onCollectionClick(e, collection) : null}
            highlightRow={collection.Id === activeCollection?.Id}
            glyph={<Glyphs.Collection16x16 />}
            nestingLevel={nestingLevel}
            controlButton={rightControl}
            accordionDefinition={{
               noPlaceholder: true
            }}
         />);
      });

      // this creates a hook internally so we cannot condition its creation call even if it wont be used
      const showMoreMenuRow =
         (<ShowMoreButtonContainer>
            <ControlButton
               key={'navMenu.feed.myFeed.showMore'}
               // eslint-disable-next-line id-denylist, id-blacklist
               label={t('header.showMore', {number: followedCollections.length - showMoreListLength})}
               onClick={() => setShowFullFeedList(true)}
            />
         </ShowMoreButtonContainer>)
      ;

      if (followedCollections.length > showMoreListLength && !showFullFeedList) {
         followedCollections = followedCollections.slice(0, showMoreListLength);
         followedCollections.push(showMoreMenuRow);
      }

      return followedCollections;
   };

   const allCollectionsSubMenu = (nestingLevel: number, enableDnD: boolean = true) => {
      if (migrationInProgress) {
         return [<MigrationMessage className="migration-message" key="migration-message">{t('collections.inMigration')}</MigrationMessage>];
      }

      const recentContent: React.JSX.Element[] = [(
         <MenuRow
            key={'navMenu.myCollection.recentlyAdded'}
            fragmentKey={'navMenuRow.myCollection.recentlyAdded'}
            label={t('collections.recentContent')}
            glyph={<FeedGlyph16x16 />}
            link={Constants.navigation.myFeedLink}
            nestingLevel={nestingLevel}
            highlightRow={location.pathname === Constants.navigation.myFeedLink || location.pathname === Constants.navigation.local.myFeedLink}
            accordionDefinition={{
               noPlaceholder: true
            }}
         />)];
      const myCollections: React.JSX.Element[] = myCollectionsSubMenu(nestingLevel, enableDnD);
      const followedCollections: React.JSX.Element[] = followedCollectionSubMenu(nestingLevel);
      return recentContent.concat(myCollections.concat(followedCollections));
   };

   const myTeamsSubMenu = (nestingLevel: number) => {
      const myTeams = (userTeamsFolderTree ?? []).map(team => {
         const rightControl = config.sidebarEnableContextMenus ?
            (<MenuRowButton>
               <PopoverButton
                  origin={TscPopoverOrigin.bottomRight}
                  buttonType={TscButtonType.tertiary}
                  themeName={TscThemeName.dusk}
                  buttonGlyph={<Glyphs.EllipsisStacked16x16/>}
                  buttonTitle={t('folders.options.buttonTitle')}
                  mobileContextMenuTitle={t('team.options.title', {teamName: team.Name})}
                  popoverMenu={<TeamMoreMenu team={team} />}
                  style={menuControlButtonStyle}
                  useMobileMenu={true}
                  mobileContextMenuZindex={cssConstants.zIndex.mobileContextMenu}
                  closeMenuOnClick={true}
                  popoverMenuMaxHeight={cssConstants.contextMenuMaxHeight}
                  testId={`navMenuRow.folderContext.${team.TeamId}`}
               />
            </MenuRowButton>) : null;

         return (
            <MenuRow
               key={`navMenu.teams.${team.TeamId}`}
               fragmentKey={`navMenuRow.teams.${team.TeamId}`}
               label={team.Name}
               link={`${Constants.navigation.teamsLink}/${team.TeamId}`}
               highlightRow={team.TeamId === activeTeam?.TeamId}
               glyph={<Glyphs.Groups16x16 />}
               nestingLevel={nestingLevel}
               // eslint-disable-next-line no-magic-numbers
               subMenu={config.sidebarShowMyFolders && config.teamConfig?.teamId === team.TeamId ? teamFoldersSubMenu(team.TeamId, 3, true) : null}
               controlButton={rightControl}
               accordionDefinition={{
                  noPlaceholder: true
               }}
               dropInfo={{
                  isValidMedia: () => true,
                  isValidFolder: () => true,
                  onDrop: content => onMoveToTeam(team, content),
                  warningModalType: BatchContentWarningModalType.Move
               }}
            />
         );
      }
      );
      return myTeams;
   };

   const myLibrarySubMenu = () => {
      const items = [
         <MenuRow
            key={'navMenu.myLibrary.rootFolder'}
            fragmentKey={'navMenuRow.myLibrary.rootFolder'}
            label={t('MyLibrary')}
            link={Constants.navigation.myLibraryLink}
            onClick={config.sidebarShowMyFolders && config.entryPoint === EntryPoint.library ? e => onFolderClick(e, personalFolderTree) : null}
            nestingLevel={2}
            controlButton={config.sidebarEnableCreateFolder && getRightControlCreate(NavigationTabTypes.myFolders)}
            // eslint-disable-next-line no-magic-numbers
            subMenu={config.sidebarShowMyFolders ? myFoldersSubMenu(3, false) : null}
            accordionDefinition={{
               isInitiallyExpanded: false
            }}
         />];
      if (config.sidebarShowMyCollections) {
         items.push(
            <MenuRow
               key={'navMenu.myCollection.allCollections'}
               fragmentKey={'navMenuRow.myCollection.allCollections'}
               label={t('collections.mine')}
               link={!migrationInProgress ? Constants.navigation.myCollectionsLink : null}
               onClick={!migrationInProgress ? e => onMyCollectionsClick(e) : null}
               nestingLevel={2}
               controlButton={!migrationInProgress && config.sidebarEnableCreateCollection && getRightControlCreate(NavigationTabTypes.myCollections)}
               // eslint-disable-next-line no-magic-numbers
               subMenu={myCollectionsSubMenu(3, false)}
               accordionDefinition={{
                  isInitiallyExpanded: false
               }}
            />);
      }
      return items;
   };

   const myFeedSubMenu = () => [
      <MenuRow
         key={'navMenu.myFeed.followed'}
         fragmentKey={'navMenuRow.myFeed.followed'}
         label={t('collections.followed')}
         link={Constants.navigation.myFeedLink}
         onClick={config.entryPoint === EntryPoint.feed ? e => onFollowedCollectionsClick(e) : null}
         nestingLevel={1}
         subMenu={followedCollectionSubMenu(2)}
         accordionDefinition={{
            isAlwaysExpanded: true
         }}
      />
   ];

   const activitySubMenu = () => [<MenuRow
      key={'navMenu.activity.captioning'}
      fragmentKey={'navMenuRow.activity.captioning'}
      label={t('Captioning')}
      link={Constants.navigation.accessibilityLink}
      nestingLevel={1}
   />];

   const adminSubMenu = () => [
      <MenuRow
         key={'navMenu.admin.analytic'}
         fragmentKey={'navMenuRow.admin.analytic'}
         label={t('Analytics')}
         link={Constants.navigation.admin.analyticsLink}
         nestingLevel={1}
      />,
      <MenuRow
         key={'navMenu.admin.userAndRoles'}
         fragmentKey={'navMenuRow.admin.userAndRoles'}
         label={t('UsersAndRoles')}
         link={Constants.navigation.admin.usersAndRolesLink}
         nestingLevel={1}
      />,
      <MenuRow
         key={'navMenu.admin.media'}
         fragmentKey={'navMenuRow.admin.media'}
         label={t('Media')}
         link={Constants.navigation.admin.mediaLink}
         nestingLevel={1}
      />,
      <MenuRow
         key={'navMenu.admin.settings'}
         fragmentKey={'navMenuRow.admin.settings'}
         label={t('Settings')}
         link={Constants.navigation.admin.settingsLink}
         nestingLevel={1}
      />,
      <MenuRow
         key={'navMenu.admin.contact'}
         fragmentKey={'navMenuRow.admin.contact'}
         label={t('ContactTechSmith')}
         link={Constants.navigation.admin.contanctTechsmithLink}
         nestingLevel={1}
      />
   ];

   const getSubMenuForTab = (type: NavigationTabTypes) => {
      if (type === NavigationTabTypes.myLibrary) {
         return myLibrarySubMenu();
      }
      if (type === NavigationTabTypes.allCollections) {
         return allCollectionsSubMenu(2);
      }
      if (type === NavigationTabTypes.teams) {
         return myTeamsSubMenu(2);
      }
      if (type === NavigationTabTypes.myFeed) {
         return myFeedSubMenu();
      }
      if (type === NavigationTabTypes.followedCollections) {
         return followedCollectionSubMenu(2);
      }
      if (type === NavigationTabTypes.accessibility) {
         return activitySubMenu();
      }
      if (type === NavigationTabTypes.admin) {
         return adminSubMenu();
      }
      if (type === NavigationTabTypes.myFolders) {
         return myFoldersSubMenu(1);
      }
      if (type === NavigationTabTypes.myCollections) {
         return myCollectionsSubMenu(2);
      }
      return null;
   };

   const topLevelOnClick = (e: React.MouseEvent<HTMLButtonElement>, tab: INavigationTab) => {
      if (config.entryPoint === EntryPoint.library) {
         if (tab.which === NavigationTabTypes.myLibrary || tab.which === NavigationTabTypes.myFolders) {
            onFolderClick(e, personalFolderTree);
         } else if (tab.which === NavigationTabTypes.myCollections || tab.which === NavigationTabTypes.allCollections) {
            if (!migrationInProgress) {
               onMyCollectionsClick(e);
            }
         } else {
            window.location.href = tab.href;
         }
      } else if (config.entryPoint === EntryPoint.feed && (tab.which === NavigationTabTypes.myFeed || tab.which === NavigationTabTypes.followedCollections)) {
         onFollowedCollectionsClick(e);
      } else {
         window.location.href = tab.href;
      }
   };

   return (
      <>
         {tabs.map(tab => {
            const subMenu = getSubMenuForTab(tab.which);
            const enableRightControlButton =
               (tab.which === NavigationTabTypes.myFolders && config.sidebarEnableCreateFolder) ||
               ((tab.which === NavigationTabTypes.myCollections || tab.which === NavigationTabTypes.allCollections) && config.sidebarEnableCreateCollection && !migrationInProgress) || (tab.which === NavigationTabTypes.teams);

            return (<MenuRow
               key={`navMenu.topLevel.${tab.which}`}
               fragmentKey={`navMenuRow.topLevel.${tab.which}`}
               label={tab.text}
               link={tab.href}
               onClick={e => topLevelOnClick(e, tab)}
               nestingLevel={0}
               highlightRow={tab.matcher ? tab.matcher(location.pathname) : false}
               controlButton={enableRightControlButton && getRightControlCreate(tab.which)}
               subMenu={subMenu}
               accordionDefinition={{
                  noPlaceholder: tabs.length <= 1,
                  isInitiallyExpanded: tab.isSelected
               }}
               dropInfo={tab.which === NavigationTabTypes.myFolders && {
                  isValidMedia: (content: IMediaDetails) => isMovableMedia(content, personalFolderTree),
                  isValidFolder: (content: IFolderTreeNode) => isMovableFolder(content, personalFolderTree),
                  onDrop: content => onMoveToFolder(personalFolderTree, content),
                  warningModalType: BatchContentWarningModalType.Move
               }}
            />);
         })}
         {showCreateCollectionModal && <CreateCollectionModal onClose={(collection: any) => closeCollectionModal(collection)}/>}
         {showCreateFolderModal && <CreateOrRenameFolderModal onClose={(folder: IFolderTreeNode) => closeFolderModal(folder)} parentFolder={!isWellKnownFolder(activeFolder?.Hash) ? activeFolder?.Hash : null} />}
         {showCreateTeamModal && <CreateTeamModal onClose={() => setShowCreateTeamModal(false)} />}
      </>
   );
};

export interface ISidebarContentProps {
   tabs: INavigationTab[];
}

export interface IStateMappedProps {
   activeCollection: ICollectionModel;
   sidebarCollections: ICollectionModel[];
   userTeamsFolderTree: ITeamModelFolderTree[];
   activeTeam: ITeamModel;
   activeFolder: IFolderTreeNode;
   personalFolderTree: IFolderTreeNode;
   teamFolderTree: IFolderTreeNode;
   migrationInProgress: boolean;
}

const mapStatesToProps = (
   {tabs}: ISidebarContentProps,
   {activeCollection, sidebarCollections}: Partial<IStateMappedProps>,
   {userTeamsFolderTree, activeTeam}: Partial<IStateMappedProps>,
   {activeFolder, personalFolderTree, teamFolderTree}: Partial<IStateMappedProps>,
   {migrationInProgress}: Partial<IStateMappedProps>
): ISidebarContentProps & IStateMappedProps => ({tabs, activeCollection, sidebarCollections, userTeamsFolderTree, activeTeam, activeFolder, personalFolderTree, teamFolderTree, migrationInProgress});

export default withMemoizedContexts(mapStatesToProps, collectionsStore, teamsStore, foldersStore, migrationStore)(SidebarContentBase);

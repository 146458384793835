import {IBaseUser, nullBaseUserFactory} from './baseUser';
import {IMediaSummary} from './mediaDetails';
import Optional from './optional';

export enum CaptioningTypes {
   Unknown = 'Unknown',
   Automatic = 'Automatic',
   ThirdParty = 'ThirdParty',
   AutoTranscribed = 'AutoTranscribed'
}

export enum CaptioningState {
   Unknown = 'Unknown',
   None = 'None',
   Transcribing = 'Transcribing',
   ReadyForEdit = 'ReadyForEdit',
   Editing = 'Editing',
   Published = 'Published',
   PendingApproval = 'PendingApproval',
   Error = 'Error'
}

export interface ICaptionData {
   CaptioningState: CaptioningState;
   CaptionJobId: string;
   CaptionServiceConfigurationId: string;
   LastUpdateDateTime: Date;
   LastUpdatedByUser: IBaseUser;
   AssignedTo: IBaseUser;
   CaptioningType: CaptioningTypes;
   Media: Optional<IMediaSummary>;
}

interface IRgbaColor {
   r: number;
   g: number;
   b: number;
   a: number;
}

export interface ICaption {
   startTime: number;
   duration: number;
   plainText: string;
}

export type Captions = ICaption[];

export interface ICaptionTrack {
   fgColor: IRgbaColor;
   bgColor: IRgbaColor;
   bgOpacity: number;
   fontFamily: string;
   fontSize: number;
   position?: string;
   barHeight?: number;
   captions: Captions;
}

export interface ITranscript {
   [key: string]: {
      displayTime: string;
      duration: number;
      text: string;
   };
}

export const nullCaptioningStatusFactory = (captioningStatusData: Partial<ICaptionData> = {}): ICaptionData => {
   const nullCaptioningStatusData = {
      CaptioningState: CaptioningState.None,
      CaptionJobId: '',
      CaptionServiceConfigurationId: '',
      LastUpdateDateTime: new Date(),
      LastUpdatedByUser: nullBaseUserFactory(),
      AssignedTo: nullBaseUserFactory(),
      CaptioningType: CaptioningTypes.Unknown,
      Media: {} as IMediaSummary
   };

   return Object.assign({}, nullCaptioningStatusData, captioningStatusData);
};

export const nullCaptionTrackFactory = (captionTrack: Partial<ICaptionTrack> = {}): ICaptionTrack => {
   const nullCaptionTrack = {
      bgColor: {r: 0, g: 0, b: 0, a: 1},
      fgColor: {r: 255, g: 255, b: 255, a: 1},
      bgOpacity: 1,
      fontFamily: 'Arial, Helvetica, sans-serif',
      fontSize: 24,
      captions: {}
   };

   return Object.assign({}, nullCaptionTrack, captionTrack);
};

import React, {createContext, ReactElement, useState} from 'react';
import config from '../service/config';
import {History} from 'history';
import {useHistory} from 'react-router-dom';
import logging from '../service/logging';
import {ITeamModel, ITeamModelFolderTree} from '../model/appModel';
import teamApi from '../service/teamApi';
import folderApi from '../service/folderApi';

interface ITeamProvider {
   userTeams: ITeamModel[];
   userTeamsFolderTree: ITeamModelFolderTree[];
   activeTeam: ITeamModel;
   history: History;
   isUpdating: boolean;
   isUpdatingUserTeams: boolean;
   canLoadMoreTeams: boolean;
}

const initialState: ITeamProvider = {
   userTeams: [],
   userTeamsFolderTree: [],
   activeTeam: null,
   history: null,
   isUpdating: false,
   isUpdatingUserTeams: false,
   canLoadMoreTeams: true
};

const store = createContext<ITeamProvider>(initialState);
const Provider = store.Provider;
let privateState = initialState;
let privateUpdateState: React.Dispatch<React.SetStateAction<ITeamProvider>> = () => null;
const publicUpdateState = (newState: Partial<ITeamProvider>) => privateUpdateState((prevState: ITeamProvider) => Object.assign({}, prevState, newState));

export const updateUserTeams = async () => {
   if (!privateState.isUpdatingUserTeams && config.featureSwitches.EnableTeams) {
      publicUpdateState({isUpdatingUserTeams: true});

      let teams: ITeamModel[];
      if (config.user.IsSignedIn) {
         try {
            teams = await teamApi.getTeamList();
         } catch (e) {
            logging.error('Failed to update collections data from server: ', e);
         }
      }

      publicUpdateState({
         userTeams: teams
      });

      publicUpdateState({isUpdatingUserTeams: false});
   }
};

export const updateUserTeamsWithFolderTrees = async () => {
   if (!privateState.isUpdatingUserTeams && config.featureSwitches.EnableTeams) {
      await updateUserTeams();

      const teamFolderTrees: ITeamModelFolderTree[] = [];
      for (const team of privateState.userTeams) {
         try {
            const folderTree = await folderApi.getFolderTree(null, team.TeamId);
            teamFolderTrees.push({
               ...team,
               Foldertree: folderTree
            });
         } catch (e) {
            logging.error('Failed to update collections data from server: ', e);
         }
      }

      publicUpdateState({
         userTeamsFolderTree: teamFolderTrees
      });

      publicUpdateState({isUpdatingUserTeams: false});
   }
};

export const renameTeam = (team: ITeamModel, newTeamName: string) => {
   const teamIndex = privateState.userTeamsFolderTree.findIndex(c => c.TeamId === team.TeamId);

   if (teamIndex !== -1) {
      const teamsCopy = Object.assign([], privateState.userTeamsFolderTree);
      teamsCopy[teamIndex].Name = newTeamName;
      publicUpdateState({userTeamsFolderTree: teamsCopy});
   } else {
      logging.error(`Team - ${team.TeamId} not found in local userTeamsFolderTree state object`);
   }
};

export const createTeam = (team: ITeamModel) => {
   const teamsCopy = Object.assign([], privateState.userTeamsFolderTree);
   teamsCopy.push(team);
   publicUpdateState({userTeamsFolderTree: teamsCopy});
};

export const removeTeam = (team: ITeamModel) => {
   const teamIndex = privateState.userTeamsFolderTree.findIndex(c => c.TeamId === team.TeamId);

   if (teamIndex !== -1) {
      const teamsCopy = Object.assign([], privateState.userTeamsFolderTree);
      teamsCopy.splice(teamIndex, 1);
      publicUpdateState({userTeamsFolderTree: teamsCopy});
   } else {
      logging.error(`Team - ${team.TeamId} not found in local userTeamsFolderTree state object`);
   }
};

export const updateActiveTeam = (team: ITeamModel) => {
   if (!privateState.isUpdating && config.featureSwitches.EnableTeams) {
      publicUpdateState({isUpdating: true});

      publicUpdateState({
         activeTeam: team
      });

      publicUpdateState({isUpdating: false});
   }
};

export const clearActiveTeam = () => {
   publicUpdateState({activeTeam: null});
};

const useProvider = (children: ReactElement) => {
   initialState.history = useHistory();
   const [state, updateState] = useState<ITeamProvider>(initialState);
   privateUpdateState = updateState;
   privateState = state;

   return (
      <Provider value={state}>
         {children}
      </Provider>
   );
};

export {
   useProvider as useTeamsProvider,
   store as teamsStore
};

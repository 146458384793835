import ApiUtil from '../util/ApiUtil';
import Constants from '../constants/Constants';
import {IMediaDetails} from '../model/mediaDetails';
import {UpsertQueryParamInUrl} from '../component/util/QueryParamsHelper';
import {CancelTokenSource, AxiosResponse, AxiosRequestConfig} from 'axios';
import {IGetTeamMemberReponseModel, ITeamModel} from '../model/appModel';
import {IGetMediaQueryParams} from './userApi';

const teamBaseUrl = '/api/v1/team';

export interface IMoveMediaRequestBody {
   MediaHashes: string[];
   ToTeamLibrary: boolean;
}

export interface IRenameTeamQueryParams {
   newTeamName: string;
}

export interface ILeaveTeamQueryParams {
   userId: string;
}

export interface ICreateTeamRequestBody {
   teamName: string;
}

const getMedia = async (teamId: string, queryParams: Partial<IGetMediaQueryParams>, CancellationToken?: CancelTokenSource): Promise<IMediaDetails[]> => {
   let url = `${teamBaseUrl}/media/${teamId}`;

   if (queryParams) {
      for (const [param, value] of Object.entries(queryParams)) {
         const queryValue = Array.isArray(value) ? value.map(v => v.toString()) : value.toString();
         url = UpsertQueryParamInUrl(url, param, queryValue);
      }
   }

   const requestConfig: AxiosRequestConfig = CancellationToken ? {cancelToken: CancellationToken.token} : null;
   const response: AxiosResponse = await ApiUtil.executeGetWithoutCacheAsync(url, [Constants.statusCodes.ok], null, requestConfig);
   return response.data as IMediaDetails[];
};

const getTeamList = async (CancellationToken?: CancelTokenSource): Promise<ITeamModel[]> => {
   const url = teamBaseUrl;

   const requestConfig: AxiosRequestConfig = CancellationToken ? {cancelToken: CancellationToken.token} : null;
   const response: AxiosResponse = await ApiUtil.executeGetWithoutCacheAsync(url, [Constants.statusCodes.ok], null, requestConfig);
   return response.data as ITeamModel[];
};

const getTeam = async (teamId: string, CancellationToken?: CancelTokenSource): Promise<ITeamModel> => {
   const url = `${teamBaseUrl}/${teamId}`;
   
   const requestConfig: AxiosRequestConfig = CancellationToken ? {cancelToken: CancellationToken.token} : null;
   const response: AxiosResponse = await ApiUtil.executeGetWithoutCacheAsync(url, [Constants.statusCodes.ok], null, requestConfig);
   return response.data as ITeamModel;
};

const moveMedia = async (teamId: string, requestBody: Partial<IMoveMediaRequestBody>, CancellationToken?: CancelTokenSource) => {
   const url = `${teamBaseUrl}/moveMedia/${teamId}`;

   const requestConfig: AxiosRequestConfig = CancellationToken ? {cancelToken: CancellationToken.token} : null;
   await ApiUtil.executePatchAsync(url, [Constants.statusCodes.noContent], requestBody, requestConfig);
};

const getTeamMembers = async (teamId: string, CancellationToken?: CancelTokenSource): Promise<IGetTeamMemberReponseModel[]> => {
   const url = `${teamBaseUrl}/${teamId}/members`;

   const requestConfig: AxiosRequestConfig = CancellationToken ? {cancelToken: CancellationToken.token} : null;
   const response: AxiosResponse = await ApiUtil.executeGetWithoutCacheAsync(url, [Constants.statusCodes.ok], null, requestConfig);
   return response.data.TeamMembers as IGetTeamMemberReponseModel[];
};

const createTeam = async (teamName: string, CancellationToken?: CancelTokenSource): Promise<ITeamModel> => {
   const url = `${teamBaseUrl}`;
   const requestBody = {
      teamName: teamName
   };
   const requestConfig: AxiosRequestConfig = CancellationToken ? {cancelToken: CancellationToken.token} : null;
   const response: AxiosResponse = await ApiUtil.executePostAsync(url, [Constants.statusCodes.created], requestBody, requestConfig);
   return response.data as ITeamModel;
};

const renameTeam = async (teamId: string, queryParams: Partial<IRenameTeamQueryParams>, CancellationToken?: CancelTokenSource) => {
   const url = `${teamBaseUrl}/${teamId}`;
   const requestConfig: AxiosRequestConfig = CancellationToken ? {cancelToken: CancellationToken.token} : null;
   await ApiUtil.executePatchAsync(url, [Constants.statusCodes.ok], queryParams, requestConfig);
};

const deleteTeam = async (teamId: string, CancellationToken?: CancelTokenSource) => {
   const url = `${teamBaseUrl}/${teamId}`;
   const requestConfig: AxiosRequestConfig = CancellationToken ? {cancelToken: CancellationToken.token} : null;
   await ApiUtil.executeDeleteAsync(url, [Constants.statusCodes.noContent], null, requestConfig);
};

const leaveTeam = async (teamId: string, queryParams: Partial<ILeaveTeamQueryParams>, CancellationToken?: CancelTokenSource) => {
   const url = `${teamBaseUrl}/${teamId}/members`;
   const requestConfig: AxiosRequestConfig = CancellationToken ? {cancelToken: CancellationToken.token} : null;
   await ApiUtil.executeDeleteAsync(url, [Constants.statusCodes.ok], queryParams, requestConfig);
};

export default {
   getMedia,
   getTeamList,
   getTeam,
   moveMedia,
   getTeamMembers,
   createTeam,
   renameTeam,
   deleteTeam,
   leaveTeam
};

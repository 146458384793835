import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {IWorkerState} from '../../model/workerState';
import styled from 'styled-components';
import {
   ControlButton,
   TscButtonType,
   TscThemeNames,
   TextInput
} from '@techsmith/tsc-cloud-style-guide';
import withMemoizedContexts from '../../context/contextContainerHoC';
import {themeStore} from '../../context/themeProvider';
import {BasicModalPortal} from '../util/ModalPortalHelper';
import cssConstants from '../../constants/cssConstants';
import {IMemberDetailsWithProfileImageModel, ITeamModel, IUserProfileWithEmailModel} from '../../model/appModel';
import config from '../../service/config';
import Constants, {GroupRoleTypes} from '../../constants/Constants';
import MemberInviteContainer from '../team/MemberInviteContainer';
import MemberAlreadyExistsError from '../../class/memberExistsError';
import {userQuotaStore} from '../../context/userQuotaProvider';
import {EntitlementType} from '../../model/userQuotas';
import {TeamInfoMessageContainer, InfoType} from '../team/TeamInfoMessageContainer';
import teamApi from '../../service/teamApi';
import {createTeam} from '../../context/teamsProvider';

const ModalFormFieldsContainer = styled.div<{ theme: TscThemeNames }>`
    width: 100%;
`;

const ModalFormFieldsRow = styled.div<{ theme: TscThemeNames }>`
    padding: 0.5rem 2rem;
    text-align: left;
`;

const ActionsButtonContainer = styled.div <{ theme: TscThemeNames }>`
    display:flex;
    justify-content: flex-end;
    gap: 0.5rem;
    margin-top: 1rem;
`;

export const CreateTeamModalBase: React.FC<ICreateTeamModalProps & IStateMappedProps> = ({onClose, QuotaEntitlementType, theme}) => {
   const {t} = useTranslation();
   const [modalState, setModalState] = React.useState<IWorkerState>({
      isWorking: false,
      hasError: false
   });
    
   const [teamName, setTeamName] = useState('');
   const [members, setMembers] = useState([]);
   const [isChildWorking, setIsChildWorking] = useState(false);
   const [errorText, setErrorText] = useState('');

   const userIsFreeUser = QuotaEntitlementType === EntitlementType.free;
   const maxNumberOfMembers = userIsFreeUser ? config.freeTeamsMaxNumberOfMembers : config.proTeamsMaxNumberOfMembers;
   const displayMemberLimitThreshold = userIsFreeUser ? config.freeTeamsShowMemberLimitThreshold : config.proTeamsShowMemberLimitThreshold;
   const quotasPromptText = userIsFreeUser ? t('team.general.maxNumberOfMembersFreeUser') : t('team.general.maxNumberOfMembersProUser');
   const quotasButtonLabel = userIsFreeUser ? t('team.buttons.upgradeToPro') : t('team.buttons.contactUs');
   const memberLimitButtonAction = userIsFreeUser ? () => upgradeToPro() : () => contactUs();

   useEffect(() => {
      const loggedInUserAsMember: IMemberDetailsWithProfileImageModel = {
         EmailAddress: config.user.EmailAddress,
         DisplayName: config.user.DisplayName,
         profileImageUrl: config.user.AvatarImageUrl,
         Role: GroupRoleTypes.manager,
         FirstAccessDate: null,
         TechSmithId: config.user.TechSmithId,
         PendingInviteStatus: false
      };

      const initialMembers: IMemberDetailsWithProfileImageModel[] = [loggedInUserAsMember];
      setMembers(initialMembers);
   }, []);

   const updateChildWorkerState = (newIsWorking: boolean, error: string) => {
      setIsChildWorking(newIsWorking);
      setErrorText(error);
   };

   const onNameChange = (evt: React.ChangeEvent<HTMLInputElement>): void => {
      setTeamName(evt.target.value);
   };
   
   const onCreateTeamButtonClick = async () => {
      setModalState({isWorking: true, hasError: false});
      try {
         const team: ITeamModel = await teamApi.createTeam(teamName);
         createTeam(team);
         setModalState({isWorking: false, hasError: false});
         onClose();
   
         window.location.href = `${Constants.navigation.teamsLink}/${team.TeamId}`;
      } catch (exception) {
         setErrorText(t('team.modal.create.errorCreatingTeam'));
         setModalState({isWorking: false, hasError: true});
      }
   };
   
   const removeFromMembers = (member: IMemberDetailsWithProfileImageModel) => {
      const memberIndex = members.findIndex(m => m.EmailAddress === member.EmailAddress);
      if (memberIndex > 0) {
         const membersCopy = Object.assign([], members);
         membersCopy.splice(memberIndex, 1);
         setMembers(membersCopy);
      }
   };

   const addMember = (member: IUserProfileWithEmailModel) => {
      const exists: boolean = members.some(m => m.EmailAddress === member.email);

      if (!exists) {
         const decoratedMember: IMemberDetailsWithProfileImageModel = {
            DisplayName: member.displayName,
            EmailAddress: member.email,
            FirstAccessDate: null,
            TechSmithId: member.techSmithId,
            profileImageUrl: member.profileImageUrl,
            Role: GroupRoleTypes.contributor,
            PendingInviteStatus: true
         };
         // eslint-disable-next-line @typescript-eslint/no-unsafe-return
         setMembers(prevState => prevState.concat(decoratedMember));
      } else {
         throw new MemberAlreadyExistsError(t('team.modal.create.memberAlreadyExists', {email: member.email}));
      }
   };

   const contactUs = () => {
      // todo: this is a placeholder, point this at the real location for contacting us
      window.open(config.environmentData.ScreencastProPurchaseLinkUrl, '_blank');
   };
   
   const upgradeToPro = () => {
      window.open(config.environmentData.ScreencastProPurchaseLinkUrl, '_blank');
   };

   return (
      <BasicModalPortal
         testId="create-team-modal"
         isLoading={modalState.isWorking}
         visible={true}
         title={t('team.modal.create.title')}
         width={cssConstants.manageCollectionModalWidth}
         onClose={() => onClose()}
      >
         <div className="modal-body">
            { members.length >= maxNumberOfMembers &&
               <ModalFormFieldsRow>
                  <TeamInfoMessageContainer infoType={InfoType.Warning} promptText={quotasPromptText} buttonLabel={quotasButtonLabel} buttonAction={memberLimitButtonAction} />
               </ModalFormFieldsRow>
            }
            {modalState.hasError && errorText &&
               <ModalFormFieldsRow>
                  <TeamInfoMessageContainer infoType={InfoType.Error} promptText={errorText} hideButton={true} />
               </ModalFormFieldsRow>
            }
            <ModalFormFieldsContainer>
               <ModalFormFieldsRow>
                  <label>{t('team.modal.create.nameTextBoxLabel')}</label>
                  <TextInput
                     value={teamName}
                     onChange={onNameChange}
                     themeName={theme}
                     placeholder={t('team.modal.create.nameTextBoxPlaceholder')}
                     required={true}
                     tabIndex={1}
                  />
               </ModalFormFieldsRow>
               <ModalFormFieldsRow>
                  <MemberInviteContainer
                     labelText="Members"
                     members={members}
                     isWorking={isChildWorking}
                     memberInputSubMessage={t('team.modal.create.membersTextBoxBottomMessage')}
                     membersCount={members.length}
                     membersLimit={maxNumberOfMembers}
                     hideMemberLimit={members.length < displayMemberLimitThreshold}
                     memberPendingInviteText={t('team.modal.create.pendingInviteStatusDisplay')}
                     removeMember={removeFromMembers}
                     addMember={addMember}
                     updateWorkerState={updateChildWorkerState}
                  />
                  <ActionsButtonContainer>
                     <ControlButton
                        label={t('team.modal.create.cancelButtonLabel')}
                        title={t('team.modal.create.cancelButtonLabel')}
                        buttonType={TscButtonType.secondary}
                        onClick={() => onClose()}
                        themeName={theme}
                        width="7rem"
                     />
                     <ControlButton
                        label={t('team.modal.create.createTeamButtonLabel')}
                        title={t('team.modal.create.createTeamButtonLabel')}
                        buttonType={TscButtonType.primary}
                        onClick={() => onCreateTeamButtonClick()}
                        themeName={theme}
                        width="7rem"
                        disabled={teamName.length === 0}
                     />
                  </ActionsButtonContainer>
               </ModalFormFieldsRow>
            </ModalFormFieldsContainer>
         </div>
      </BasicModalPortal>
   );
};

export interface ICreateTeamModalProps {
   onClose(): void;
}

export interface IStateMappedProps {
   QuotaEntitlementType: EntitlementType;
   theme: TscThemeNames;
}

const mapStatesToProps = (
   {onClose}: ICreateTeamModalProps,
   {QuotaEntitlementType, theme}: Partial<IStateMappedProps>
): IStateMappedProps & ICreateTeamModalProps => ({onClose, QuotaEntitlementType, theme});

export default withMemoizedContexts(mapStatesToProps, userQuotaStore, themeStore)(CreateTeamModalBase);
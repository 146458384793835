import React from 'react';
import {ControlButton, TscButtonType, TscStyles} from '@techsmith/tsc-cloud-style-guide';
import styled from 'styled-components';

const TeamQuotasWrapper = styled.div`
   display: flex;
   align-items: center;
   border-radius: ${TscStyles.border.radius.xl};
   border: 2px solid ${(props: {borderColor: string}) => props.borderColor || TscStyles.color.message.caution};
   background: rgba(235, 174, 33, 0.1);
   box-shadow: ${TscStyles.shadow.short};
   padding: 0.5rem;
   box-sizing: border-box;
   weight: 600;
   font-size: 0.75rem
   color: red;
   justify-content: flex-end;

   .teams-action-button {
      background: linear-gradient(to right, ${TscStyles.color.action.screencastPrimaryStop1}, ${TscStyles.color.action.screencastPrimaryStop2});
      margin-left: 0.25rem;
   }
`;

const QuotasPromptWrapper = styled.span`
   font-size: 12px;
   font-weight: ${TscStyles.font.weight.semibold};
   margin: 0 auto;
`;

export const InfoType = {
   Info: TscStyles.color.message.info,
   Warning: TscStyles.color.message.caution,
   Error: TscStyles.color.message.warning
};

export const TeamInfoMessageContainer: React.FC<ITeamInfoMessageContainerProps> = ({infoType, promptText, hideButton, buttonLabel, buttonAction}) =>
   (<TeamQuotasWrapper borderColor={infoType}>
      <QuotasPromptWrapper>
         {promptText}
      </QuotasPromptWrapper>
      {!hideButton &&
      <ControlButton
         buttonType={TscButtonType.secondary}
         label={buttonLabel}
         className={'teams-action-button'}
         onClick={buttonAction ? () => buttonAction() : null}
      />
      }
   </TeamQuotasWrapper>
   );

export interface ITeamInfoMessageContainerProps {
   infoType: string;
   promptText: string;
   hideButton?: boolean;
   buttonLabel?: string;
   buttonAction?(): void;
}